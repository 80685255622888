import axios from "axios";

const obtenerTrazabilidadOD = async (setData, setError, inputVal) => {
  const url = `${process.env.REACT_APP_CARGOEX_BACKEND_URL}app/cargoex/app/consultaOdApiSimple`
  const apiKey = process.env.REACT_APP_X_API_KEY
  try {
    const req = await axios.post(url, { OD: inputVal }, {
      headers: {
        'X-API-KEY': apiKey,
        'Content-Type': 'text/plain',
      }
    })
    if (!req.status === 200) {
      throw new Error("Algo fue mal, haciendo la request.");
    }
    if(!Array.isArray(req.data.msj)){
      throw new Error(`La order con codigo: ${inputVal} no existe.`);
    }
    setData(req.data);
  } catch (error) {
    setError(error.message);
  }
}

export default obtenerTrazabilidadOD;
import React, { memo, useState, useEffect } from "react";
import { obtenerChoferes, actualizarChoferes, eliminarChoferes } from "../../services/PortalChoferesService";
import TableChoferes from "../../components/AdministracionComponentes/componentesChoferes/tableChoferes";
import EditModalChoferes from "../../components/AdministracionComponentes/componentesChoferes/EditModalChoferes";
import DeleteModalChoferes from "../../components/AdministracionComponentes/componentesChoferes/DeleteModalChoferes";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { camposChoferes } from "../../components/AdministracionComponentes/componentesUsuariosChoferes/ConfigCampos";

const PortalChoferes = () => {
  const [choferes, setChoferes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedChoferesForEdit, setSelectedChoferesForEdit] = useState(null);
  const [selectedChoferesForDelete, setSelectedChoferesForDelete] = useState(null);

  useEffect(() => {
    const cargarChoferes = async () => {
      setLoading(true);
      try {
        const data = await obtenerChoferes();
        setChoferes(data);
      } catch (error) {
        console.error("Error al obtener los Choferes:", error);
      } finally {
        setLoading(false);
      }
    };
    cargarChoferes();
  }, []);

  const onEditClick = (choferes) => {
    setSelectedChoferesForEdit(choferes);
    setEditModalOpen(true);
  };

  const onDeleteClick = (choferes) => {
    setSelectedChoferesForDelete(choferes);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await eliminarChoferes(selectedChoferesForDelete.ID);
      const filteredChoferes = choferes.filter((choferes) => choferes.ID !== selectedChoferesForDelete.ID);
      setChoferes(filteredChoferes);
      setDeleteModalOpen(false);
      toast.success("Choferes eliminado con éxito");
    } catch (error) {
      console.error("Error al eliminar el Choferes:", error);
      toast.error("Error al eliminar el Choferes");
    }
  };

  const handleSaveEdit = async (updatedChofere) => {
    try {
      await actualizarChoferes(updatedChofere.ID, updatedChofere);
      const updatedChoferes = choferes.map((chofer) => (chofer.ID === updatedChofere.ID ? updatedChofere : chofer));
      setChoferes(updatedChoferes);
      setEditModalOpen(false);
      toast.success("Chofer actualizado con éxito");
    } catch (error) {
      console.error("Error al actualizar el chofer:", error);
      toast.error("Error al actualizar el chofer");
    }
  };

  const columns = [
    {
      field: "acciones",
      headerName: "Acciones",
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => onEditClick(params.row)}>
            <EditIcon style={{ color: "#041562" }} />
          </IconButton>
          <IconButton onClick={() => onDeleteClick(params.row)}>
            <DeleteIcon style={{ color: "red" }} />
          </IconButton>
        </>
      ),
      width: 100,
    },
    { field: "NOMBRE", headerName: "Nombre", width: 250 },
    { field: "RUT", headerName: "Rut", width: 130 },
    { field: "CIUDAD", headerName: "Ciudad", width: 100 },
    { field: "PASSWORD", headerName: "Password", width: 100 },
    { field: "PASSWORD_HASH", headerName: "Password Hash", width: 200 },
    { field: "MAIL", headerName: "Mail", width: 150 },
    { field: "COD_CHOFER", headerName: "Código de Chofer", width: 100 },
    { field: "ESTADO", headerName: "Estado", width: 100 },
    { field: "TRANSPORTISTA", headerName: "Transportista", width: 100 },
    { field: "TELEFONO", headerName: "Teléfono", width: 100 },
    { field: "CHOFER_ADMINISTRATIVO", headerName: "Chofer Administrativo", width: 100 },
    { field: "AGENTE_ASIGNADO", headerName: "Agente Asignado", width: 180 },
    { field: "PERMITE_RETIROS", headerName: "Permite Retiros", width: 140 },
    { field: "PERMITE_RETORNOS", headerName: "Permite Retornos", width: 140 },
    { field: "PERMITE_IMPRESORA", headerName: "Permite Impresora", width: 140 },
    { field: "PERMITE_ENTREGAS", headerName: "Permite Entregas", width: 140 },
    { field: "PERMITE_INSPECCION", headerName: "Permite Inspeccion", width: 140 },
    { field: "PERMITE_APP", headerName: "Permite resto de app", width: 140 },
    {
      field: "FH_SYS",
      headerName: "Fecha del sistema",
      width: 180,
      renderCell: (params) => formatDate(params.value),
    },
    { field: "TOKEN_SESION", headerName: "Token de Sesión", width: 200 },
  ];

  /* formatear fecha de tabla */
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    const cargarChoferes = async () => {
      setLoading(true);
      try {
        const data = await obtenerChoferes();
        setChoferes(data);
      } catch (error) {
        console.error("Error al obtener los Choferes:", error);
      } finally {
        setLoading(false);
      }
    };
    cargarChoferes();
  }, []);

  const reloadChoferes = async () => {
    setLoading(true);
    try {
      const data = await obtenerChoferes();
      setChoferes(data);
    } catch (error) {
      console.error("Error al recargar los choferes:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <TableChoferes columns={columns} rows={choferes} loading={loading} reloadChoferes={reloadChoferes} />
      {selectedChoferesForEdit && (
        <EditModalChoferes
          open={editModalOpen}
          onClose={() => {
            setEditModalOpen(false);
            setSelectedChoferesForEdit(null);
          }}
          choferes={selectedChoferesForEdit}
          onSave={handleSaveEdit}
          campos={camposChoferes}
        />
      )}
      {selectedChoferesForDelete && (
        <DeleteModalChoferes
          open={deleteModalOpen}
          onClose={() => {
            setDeleteModalOpen(false);
            setSelectedChoferesForDelete(null);
          }}
          onConfirm={handleConfirmDelete}
        />
      )}
    </div>
  );
};

export default memo(PortalChoferes);

import axios from "axios";
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

export const listarVideos = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}storageUtilitario/listarVideos`);
    return response.data;
  } catch (error) {
    console.error("Error obteniendo la lista de videos:", error);
    throw error;
  }
};

export const listarInformatica = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}storageUtilitario/listarInformatica`);
    return response.data; // Retorna la lista de videos
  } catch (error) {
    console.error("Error obteniendo la lista de videos:", error);
    throw error; // Lanza el error para manejarlo donde se llame esta función
  }
};


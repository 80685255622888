import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

export const obtenerPreciosCombustibles = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}inicio/obtenerPreciosCombustibles`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener precios de combustibles:", error);
    throw error;
  }
};

export async function calcularCostos(data) {
  try {
    const response = await axios.post(
      `${BASE_API_URL}inicio/calcularCostos`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error al calcular costos:", error);
    throw error;
  }
}
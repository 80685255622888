import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getOrdenesManifiestosRuta } from "../../../services/ManifiestoRutaPorOdServices";
import { CircularProgress, Button, Paper, Grid, Autocomplete, TextField } from "@mui/material";
import { toast } from "react-toastify";
import {
  insertPickingTemp,
  deletePickingTemp,
  downloadManifestPdfGlobal,
  buscarDatosPickingTempManifiestoRuta,
  getChoferesXId,
  getPatentes,
  createManifiestoAndPicking,
} from "../../../services/ManifiestosServices";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import {
  AutocompletePersonalizado,
  BotonPersonalizado,
} from "../../../components/OperacionesComponentes/componentesVisuales/UtilesPersonalizados";
import { handleChangePatente } from "../../../components/OperacionesComponentes/componentesManifiestoRuta/EventHandler";
import TablaOrdenesSeleccion from "../../../components/OperacionesComponentes/componentesManifiestoRutaPorOd/TablaOrdenesSeleccion";
import TablaOrdenes from "../../../components/OperacionesComponentes/componentesManifiestoRutaPorOd/TablaOrdenes";

const ManifiestoRutaPorOd = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_user = userInfo ? userInfo.id : null;
  const [ordenes, setOrdenes] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = React.useState(false);
  const [choferes, setChoferes] = useState([]);
  const [codigoChofer, setCodigoChofer] = useState("");
  const [selectedChofer, setSelectedChofer] = useState(null);
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [dataTabla, setDataTabla] = useState([]);
  const [dataTablaCarga, setDataTablaCarga] = useState([]);
  const [totalBultos, setTotalBultos] = useState(0);
  const [selectedPeoneta, setSelectedPeoneta] = useState(null);
  const [idPeoneta, setIdPeoneta] = useState("");
  const [peonetas, setPeonetas] = useState([]);
  const [patentes, setPatentes] = useState([]);
  const [selectedPatente, setSelectedPatente] = useState("");
  const [filterText, setFilterText] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  useEffect(() => {
    const Patentes = async () => {
      try {
        const patentesData = await getPatentes();
        const patentesOptions = patentesData.map((patentes) => ({
          value: patentes.ID,
          label: patentes.PATENTE,
        }));
        console.log(patentesOptions);
        setPatentes(patentesOptions);
      } catch {}
    };
    Patentes();
  }, []);

  const getFecha = () => {
    try {
      const fechaActual = new Date();
      const options = {
        timeZone: "America/Santiago",
        hour12: false,
      };

      const fecha = fechaActual.toLocaleDateString("es-CL", options);

      const [dia, mes, año] = fecha.split("-");

      const nuevaFecha = `${año}-${mes}-${dia}`;

      const hora = fechaActual.toLocaleTimeString("es-CL", {
        ...options,
        hour12: false,
      });

      const fechaHoraFormateada = `${nuevaFecha} ${hora}`;

      return fechaHoraFormateada;
    } catch (error) {
      console.error("Error al obtener la fecha:", error);
      return null; // O cualquier otro manejo de errores que desees
    }
  };

  useEffect(() => {
    const fetchOrdenes = async () => {
      try {
        let agente;
        if (userInfo.iata_agentes === "SCL") {
          agente = "BODEGA SANTIAGO";
        } else {
          agente = userInfo.AGENTE_ASIGNADO;
        }

        const datos = {
          AGENTE_ASIGNADO: agente,
          COMUNA: userInfo.iata_agentes,
        };
        const OrdenesData = await getOrdenesManifiestosRuta(datos);
        console.log(OrdenesData);
        setDataTabla(OrdenesData);
        setOrdenes(OrdenesData);
        setLoadingData(false);
      } catch (error) {
        console.error("Failed to fetch ordenes", error);
        setLoadingData(false);
      }
    };
    if (id_user) {
      fetchOrdenes();
    }
  }, [id_user, userInfo]);

  useEffect(() => {
    const fetchChoferes = async () => {
      try {
        const choferesData = await getChoferesXId(id_user);
        setChoferes(choferesData);
        const choferesOptions = choferesData.map((choferes) => ({
          value: choferes.COD_CHOFER,
          label: choferes.NOMBRE,
        }));
        setPeonetas(choferesOptions);
      } catch (error) {
        console.error("Failed to fetch choferes", error);
      }
    };

    fetchChoferes();
  }, [id_user]);

  const handleChangeChofer = async (newValue) => {
    setCodigoChofer(newValue ? newValue.COD_CHOFER : "");

    const datos = {
      COD_USUARIO: id_user,
      COD_CHOFER: newValue.COD_CHOFER,
      PROCESO: "SALIDARUTARETIROS", //cambiar a SALIDARUTARETIROS
    };
    const dataPickingTemp = await buscarDatosPickingTempManifiestoRuta(datos);
    console.log(dataPickingTemp);

    let totalBultosTemp = 0;
    if (dataPickingTemp && dataPickingTemp.results) {
      dataPickingTemp.results.forEach((orden) => {
        totalBultosTemp += orden.BULTOS;
      });
      setDataTablaCarga(dataPickingTemp.results.length);
    }

    setTotalBultos(totalBultosTemp);

    if (dataPickingTemp && dataPickingTemp.results) {
      setDataTablaCarga(dataPickingTemp.results);
    } else {
      setDataTablaCarga([]);
    }
  };

  const handleChangePeoneta = (event, newValue) => {
    if (newValue === null) {
      setSelectedPeoneta(null);
      setIdPeoneta("");
    } else {
      setSelectedPeoneta(newValue);
      setIdPeoneta(newValue.value);
    }
  };

  const handleDeleteButtonClick = async (orden) => {
    try {
      await deletePickingTemp({
        OD: orden,
        COD_USUARIO: id_user,
        PROCESO: "SALIDARUTARETIROS", //cambiar a SALIDARUTARETIROS
      });
      setDataTablaCarga((prevData) => prevData.filter((item) => item.OD !== orden));

      let agente;
      if (userInfo.iata_agentes === "SCL") {
        agente = "BODEGA SANTIAGO";
      } else {
        agente = userInfo.name;
      }

      const datos = {
        AGENTE_ASIGNADO: agente,
        COMUNA: userInfo.iata_agentes,
      };
      const OrdenesData = await getOrdenesManifiestosRuta(datos);
      setDataTabla(OrdenesData);
      setOrdenes(OrdenesData);
    } catch (error) {}
  };

  const crearManifiesto = async () => {
    // Validación inicial
    if (codigoChofer === idPeoneta) {
      toast.error("Chofer y peoneta no deben ser los mismos");
      return;
    }

    try {
      const fecha = getFecha(); // Obtén la fecha actual

      // Datos para crear manifiesto y picking
      const data = {
        FH_CREACION: fecha,
        FH_CIERRE: fecha,
        COD_USUARIO: id_user,
        PROCESO: "SALIDARUTARETIROS", // Cambiar a SALIDARUTARETIROS
        ID_CLIENTE: 0,
        COD_CHOFER: codigoChofer,
        ESTADO: "CERRADO",
        COD_OPERADOR_ENTRADA: codigoChofer,
        COD_CHOFER_ENTRADA: codigoChofer,
        NOMBRE_CHOFER_ENTRADA: userInfo.name,
        SELLOS: "RETIROS",
        CANTIDAD_VALIJAS: 0,
        TOTAL_BULTOS: totalBultos,
        TIPO_RUTA: "RETIROS",
        TIPO_MANIFIESTO: "SALIDARUTARETIROS", // Cambiar a SALIDARUTARETIROS
        ORIGEN: userInfo.iata_agentes,
        DESTINO: userInfo.iata_agentes,
        NOTA: "",
        FH_MAIL: "",
        RUTA: userInfo.nombre_ciudad_agentes,
        COD_PEONETA: idPeoneta,
        PATENTE: selectedPatente.label,
        AGENTE_ASIGNADO: userInfo.AGENTE_ASIGNADO,
      };

      // Llama a la nueva función combinada
      const numeroManifiesto = await createManifiestoAndPicking(data);

      // Muestra un mensaje de éxito si todo va bien
      toast.success(`Manifiesto creado con éxito. N°: ${numeroManifiesto}`);
      return numeroManifiesto;
    } catch (error) {
      // Manejo de errores
      console.error("Error al crear el manifiesto y picking:", error);
      toast.error("Error al crear el manifiesto. Por favor, intente nuevamente.");
    }
  };

  const handleCrearManifiesto = async () => {
    setLoading(true);
    if (!codigoChofer) {
      toast.error("Debe seleccionar un chofer antes de continuar!!!");
      setLoading(false);
      return;
    } else if (dataTablaCarga.length === 0) {
      toast.error("Manifiestos debe contener una orden!!!");
      setLoading(false);
      return;
    } else {
      Swal.fire({
        title: "Seguro de Crear Manifiesto?",
        text: "Esta a punto de crear un nuevo Manifiesto!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Crear!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const numeroManifiesto = await crearManifiesto();
          Swal.fire({
            title: "Manifiesto Creado",
            text: "N°: " + numeroManifiesto,
            icon: "success",
          });
          try {
            await downloadManifestPdfGlobal(numeroManifiesto, "SALIDARUTARETIROS", "", selectedChofer.NOMBRE, false); //cambiar a SALIDARUTARETIROS
          } catch (error) {
            toast.error("Error al descargar PDF!!!");
            return;
          }
          resetState();
        }
        setLoading(false);
      });
    }
  };

  const cargarOrdenesSeleccionadas = async () => {
    if (!codigoChofer) {
      toast.error("Seleccione un chofer antes de continuar");
      return;
    }
    if (
      userInfo.tipo_usuario !== "AGENTE" &&
      userInfo.tipo_usuario !== "AGENTE_HUB" &&
      userInfo.tipo_usuario !== "AGENTE_ADMIN" &&
      !selectedPatente
    ) {
      toast.error("Debe seleccionar una patente antes de continuar!!!");
      return;
    }
    setLoadingData(true);

    try {
      const selectedData = ordenes.filter((item) => selectedItems.has(item.OD));

      for (const item of selectedData) {
        await insertPickingTemp({
          OD: item.OD,
          COD_USUARIO: id_user,
          COD_CHOFER: codigoChofer,
          COMUNA: userInfo.iata_agentes,
          PROCESO: "SALIDARUTARETIROS", //cambiar a SALIDARUTARETIROS
        });
        setTotalBultos((prevTotal) => prevTotal + item.BULTOS);
      }
      setDataTablaCarga((prevData) => [...prevData, ...selectedData]);

      let agente;
      if (userInfo.iata_agentes === "SCL") {
        agente = "BODEGA SANTIAGO";
      } else {
        agente = userInfo.AGENTE_ASIGNADO;
      }

      const datos = {
        AGENTE_ASIGNADO: agente,
        COMUNA: userInfo.iata_agentes,
      };
      const OrdenesData = await getOrdenesManifiestosRuta(datos);
      setDataTabla(OrdenesData);
      setOrdenes(OrdenesData);
      setLoadingData(false);
    } catch (error) {
      console.error("Error al insertar los datos:", error);
    } finally {
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedItems = new Set(dataTabla.map((item) => item.OD));
      setSelectedItems(newSelectedItems);
      return;
    }
    setSelectedItems(new Set());
  };

  const handleCheck = (id) => {
    setSelectedItems((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const filteredData = dataTabla.filter((row) =>
    Object.values(row).some(
      (value) =>
        value !== null && value !== undefined && value.toString().toLowerCase().includes(filterText.toLowerCase()),
    ),
  );

  const resetState = () => {
    setSelectedChofer(null);
    setSelectedPeoneta(null);
    setLoading(false);
    setDataTablaCarga([]);
    setCodigoChofer("");
    setTotalBultos(0);
    setSelectedPatente("");
  };

  return (
    <div>
      <Paper style={{ padding: "10px" }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) ? 4 : 3}
            lg={["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) ? 4 : 3}
          >
            <Autocomplete
              disableClearable
              id="combo-box-chofer"
              options={choferes}
              getOptionLabel={(option) => `${option.NOMBRE} (ID: ${option.COD_CHOFER})`}
              isOptionEqualToValue={(option, value) => option.COD_CHOFER === value.COD_CHOFER}
              value={selectedChofer}
              onChange={(event, newValue) => {
                setSelectedChofer(newValue);
                handleChangeChofer(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Seleccione un Chofer" variant="outlined" fullWidth size="small" />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) ? 4 : 3}
            lg={["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) ? 4 : 3}
          >
            <Autocomplete
              //disableClearable
              value={selectedPeoneta}
              disablePortal
              id="size-small-outlined-multi"
              size="small"
              options={peonetas}
              onChange={handleChangePeoneta}
              sx={{ width: "100%" }}
              PaperComponent={({ children }) => (
                <Paper
                  sx={{
                    width: "100%",
                    textAlign: "left",
                    "& li": { textAlign: "left" },
                  }}
                >
                  {children}
                </Paper>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Seleccione un peoneta" variant="outlined" fullWidth size="small" />
              )}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />
          </Grid>
          {!["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) ? (
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <AutocompletePersonalizado
                selectedValue={selectedPatente}
                setSelectedValue={setSelectedPatente}
                options={patentes}
                onChange={(event, newValue) => handleChangePatente(event, newValue, setSelectedPatente)}
                label="Seleccione una patente"
              />
            </Grid>
          ) : null}
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Button
              sx={{
                width: "100%",
                backgroundColor: "#041562",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#031042",
                },
              }}
              onClick={handleCrearManifiesto}
              variant="contained"
              color="primary"
              size="medium"
            >
              {loading ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Crear Manifiesto"}
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
            <BotonPersonalizado
              label="Cargar Ordenes Seleccionadas"
              onClick={cargarOrdenesSeleccionadas}
              color="#041562"
              size="medium"
              fullWidth={true}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
            {loadingData ? (
              <CircularProgress
                size={50}
                sx={{ position: "relative", top: "5%", left: "50%", marginTop: 2, marginLeft: -12 }}
              />
            ) : (
              <Box sx={{ overflowX: "auto" }}>
                <TablaOrdenesSeleccion
                  dataTabla={dataTabla}
                  filterText={filterText}
                  setFilterText={setFilterText}
                  selectedItems={selectedItems}
                  handleSelectAllClick={handleSelectAllClick}
                  filteredData={filteredData}
                  handleCheck={handleCheck}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={(event, newPage) => setPage(newPage)}
                />
              </Box>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4} style={{ marginTop: "10px", marginBottom: "10px" }}>
          {loadingData ? (
            <CircularProgress
              size={50}
              sx={{ position: "relative", top: "5%", left: "50%", marginTop: 2, marginLeft: -12 }}
            />
          ) : (
            <TablaOrdenes
              dataTablaCarga={dataTablaCarga}
              loading={loading}
              handleDeleteButtonClick={handleDeleteButtonClick}
            />
          )}
        </Grid>
      </Paper>
    </div>
  );
};

export default ManifiestoRutaPorOd;

import { TableContainer, Paper, Typography, Table, TableHead, TableRow, TableCell, TableBody, Box, Grid} from '@mui/material';
import { GoogleMap, Marker } from "@react-google-maps/api";

function DetalleOD({data, ciudadesPrincipales, inputRef}){
    const center = { lat: -35.6751, lng: -71.543 };
    const selectedFields = ['OD', 'FH_GESTION', 'ESTADO_DESCRIPCION', 'NOMBRE', 'RUT', 'CANAL', 'NOTA'];


    return (
        <>
            {/* Contenedor principal con Grid para columnas */}
            {Object.keys(data).length > 0 ? (
                <Grid container spacing={3} sx={{ px: 2, py: 3 }}>
                    {/* Columna de Detalle */}
                    <Grid item xs={12} md={6} sx={{ height: '100%' }}>
                        <TableContainer
                            component={Paper}
                            sx={{
                                mx: 'auto',
                                width: '100%',
                                height: '100%',
                                minHeight: '400px', // Asegurar que tenga al menos 400px
                                boxShadow: 3,
                                borderRadius: 2,
                                overflow: 'hidden',
                                p: 2,
                            }}
                        >
                            {/* Mostrar detalles de la orden */}
                            {typeof inputRef.current === 'string' && inputRef.current.trim() !== '' ? (
                                <Typography
                                    variant="h6"
                                    component="div"
                                    sx={{
                                        fontWeight: 600,
                                        backgroundColor: '#f9f9f9',
                                        borderBottom: 1,
                                        borderColor: '#e0e0e0',
                                        textAlign: 'center',
                                        py: 1,
                                    }}
                                >
                                    Detalle Orden
                                </Typography>
                            ) : null}

                            <Table sx={{ width: '100%', height: '100%' }}>
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: '#f0f0f0' }}>
                                        {/* Agregar los encabezados de la tabla aquí */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.msj &&
                                        Array.isArray(data.msj) &&
                                        data.msj.map((entry, index) =>
                                            selectedFields.map((key) => (
                                                <TableRow
                                                    key={`${index}-${key}`}
                                                    sx={{
                                                        '&:hover': {
                                                            backgroundColor: '#fafafa',
                                                        },
                                                    }}
                                                >
                                                    <TableCell
                                                        sx={{
                                                            textTransform: 'capitalize',
                                                            fontWeight: 500,
                                                            color: '#333',
                                                        }}
                                                    >
                                                        {key.replace(/_/g, ' ')}
                                                    </TableCell>
                                                    <TableCell sx={{ color: '#555' }}>
                                                        {entry[key] || 'N/A'}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    {/* Columna de Mapa */}
                    <Grid item xs={12} md={6} sx={{ height: '100%' }}>
                        <Box
                            sx={{
                                width: '100%',
                                height: {
                                    xs: '100%',   // altura Default
                                    sm: '400px',  // settear altura en mobile
                                },
                                margin: 'auto', // Centrar
                            }}
                        >
                            {ciudadesPrincipales.length > 0 ? (
                                <GoogleMap
                                    mapContainerStyle={{
                                        width: '100%',
                                        height: '100%', // Asegurar que ocupe todo el espacio
                                    }}
                                    center={center}
                                    zoom={3}
                                    options={{
                                        restriction: {
                                            latLngBounds: {
                                                north: -15.0,
                                                south: -60.0,
                                                west: -84.0,
                                                east: -62.0,
                                            },
                                            strictBounds: false,
                                        },
                                    }}
                                >
                                    {ciudadesPrincipales.map((ciudadPrincipal, index) => (
                                        <Marker
                                            key={index}
                                            position={{ lat: ciudadPrincipal.lat, lng: ciudadPrincipal.lng }}
                                        />
                                    ))}
                                </GoogleMap>
                            ) : Array.isArray(data.msj) ? (
                                <Typography sx={{ color: 'black', p: 2 }}>
                                    No hay trazabilidad disponible
                                </Typography>
                            ) : null}
                        </Box>
                    </Grid>
                </Grid>
            ) : null}
        </>
    );

}

export default DetalleOD;
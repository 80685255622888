import {
  Typography,
  Box,
  AppBar,
  Toolbar,
  Icon
} from '@mui/material';

function ResponsableAppBar({data}) {
    let nombre = null
    let codCliente = null
    let telefono = null
    const BASE_URL = process.env.REACT_APP_LOGOS_URL
    let imgUrl = null
    if(Array.isArray(data.msj) && Array(data.msj).length > 0){
        nombre = data.msj[0].CANAL || data.msj[0].NOM_CLIENTE
        codCliente = data.msj[0].COD_CLIENTE
        imgUrl = `${BASE_URL}${codCliente}/${codCliente}.png`
        telefono = data.msj[0].TELEFONO_CLIENTE
    }
    return (
        <AppBar
            position="static"
            sx={{ backgroundColor: '#ffffff', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
        >
            {/*Mostrar barra con detalle de empresa resposnable*/}
            {Object.keys(data).length > 0 ?
                <Toolbar sx={{ display: 'flex', gap: "20px" }}>
                    <Icon
                        edge="start"
                        aria-label="DHL logo"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 88,
                            height: 88,
                        }}
                    >
                        <Box
                            component="img"
                            src={imgUrl}
                            alt="Logo"
                            sx={{
                                width: '100%',
                                height: 'auto',
                                maxWidth: '80px',
                                background: "transparent",
                                objectFit: 'contain',
                                mixBlendMode: "multiply",
                            }}
                        />
                    </Icon>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between', // Ensures items are on opposite sides
                            alignItems: 'center',
                            width: '100%',
                            flexDirection: {
                                xs: 'column', // Stack items vertically on smaller screens
                                sm: 'row',    // Place items in a row on larger screens
                            },
                            gap: { xs: 2, sm: 0 }, // Add spacing for stacked items on small screens
                        }}
                    >
                        <Typography style={{color:"black"}}  sx={{
                                color: 'black',
                                display: { xs: 'none', sm: 'block' }, // Only show on `sm` and above
                            }}>

                        {nombre}
                        </Typography>
                        <Typography
                            sx={{
                                color: 'black',
                                display: { xs: 'none', sm: 'block' }, // Only show on `sm` and above
                            }}
                        >
                            Para mayor información, contacte al número:    <img
                                src="/assets/telephone-svgrepo-com.svg"
                                alt="Logo"
                                style={{
                                    height: 25,
                                    marginBottom: '6px',
                                    marginLeft: '8px',
                                }}
                            />
                            {telefono}
                        </Typography>

                        <Typography
                            sx={{
                                color: 'black',
                                display: { xs: 'block', sm: 'none' }, // Only show on `xs`
                            }}
                        >
                            Contacto:
                            <img
                                src="/assets/telephone-svgrepo-com.svg"
                                alt="Logo"
                                style={{
                                    height: 25,
                                    marginBottom: '6px',
                                    marginLeft: '8px',
                                }}
                            />

                            {telefono}
                        </Typography>
                    </Box>

                </Toolbar>
                : <Typography style={{ color: "black", padding: "20px" }}>Busqueda por OD</Typography>}

        </AppBar>
    )

}

export default ResponsableAppBar;
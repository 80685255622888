import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

/* Obtener Tipo usuario por Id usuario */
export const buscarTipoUsuario = async (id) => {
  try {
    const response = await axios.post(`${BASE_API_URL}gestion/getObtenerClienteUsuario`, {
      id,
    });
    if (response.data && response.data.length > 0) {
      const tipoUsuario = response.data[0].tipo_usuario;
      return tipoUsuario;
    } else {
      console.log("No hay datos desde APS.JS tipo Usuario");
      return null;
    }
  } catch (error) {
    console.error("Error obteniendo datos Tipo Usuario desde API.js", error);
    throw error;
  }
};

export const CentroCostoClientes = async (COD_CLIENTE) => {
  try {
    const response = await axios.post(`${BASE_API_URL}gestion/getObtenerCentroCosto`, {
      COD_CLIENTE,
    });
    return response.data;
  } catch (error) {
    console.error("Hubo un error al hacer la solicitud:", error);
    throw error;
  }
};

/* Obtener Codigo Cliente por Id usuario   getObtenerCentroCosto   */
export const buscarCliente = async (id) => {
  try {
    const response = await axios.post(`${BASE_API_URL}gestion/getObtenerClienteUsuario`, {
      id,
    });

    if (response.data.length > 0) {
      const codCliente = response.data[0].cod_cliente;
      return codCliente;
    } else {
      console.log("El array de datos está vacío");
      return null;
    }
  } catch (error) {
    console.error("Error obteniendo Cliente segun ID desde API.JS", error);
    throw error;
  }
};

export const createOrderAPI = async (formData) => {
  try {
    const response = await axios.post("https://app.cargoex.cl/app/cargoex/app/despacho", formData, {
      headers: {
        "X-API-KEY": "55IcsddHxiy2E3q653RpYtb",
      },
    });

    if (response.status !== 200) {
      throw new Error("Error al crear la orden");
    }
    return response.data;
  } catch (error) {
    console.error("Hubo un error al hacer la solicitud:", error);
    throw error;
  }
};

/* Obtener Clientes */
export const obtenerClientesRetiro = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}gestion/getClientesRetiros`);
    return response.data;
  } catch (error) {
    console.error("Error fetching getClientesRetiros:", error);
    throw error;
  }
};

/* Comparar Ciudades Homologadas */
export const obtenerCiudadesHomologadas = async (ciudades) => {
  try {
    const response = await axios.post(`${BASE_API_URL}gestion/getCiudadesHomologadas`, { Ciudades: ciudades });

    if (response.data.success) {
      return response.data; 
    } else {
      throw new Error(response.data.message); 
    }
  } catch (error) {
    console.error("Error en obtenerCiudadesHomologadas:", error.message);
    throw error;
  }
};



export const getClientes = async (id) => {
  try {
    const response = await axios.post(`${BASE_API_URL}gestion/getClientes`, { id });
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error(`Error del servidor: ${response.data.message}`);
    }
  } catch (error) {
    console.error("Error en getClientes:", error.message);
    throw new Error(`Error al obtener detalles del cliente: ${error.message}`);
  }
};

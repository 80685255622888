import React, { useState } from "react";
import { TextField, Paper, Grid, Button } from "@mui/material";
import { SolicitudOrden } from "../../services/operacionesService";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const SolicitudModificacion = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const [orden, setOrden] = useState(""); 
  const [text, setText] = useState(""); 

  const handleChangeOd = (e) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      setOrden(value);
    }
  };

  const handleChangeText = (e) => {
    setText(e.target.value);
  };

  const handleEnviar = async () => {
    if (!orden || !text) {
      Swal.fire({
        icon: "warning",
        title: "Campos incompletos",
        text: "Por favor, complete todos los campos antes de enviar.",
      });
      return;
    }

    const data = {
      OD: orden,
      SOLICITUD: text,
      USUARIO: userInfo.id,
    };

    try {
      const response = await SolicitudOrden(data);
      if (response.success) {
        // Si la orden existe y la solicitud se envió correctamente
        Swal.fire({
          icon: "success",
          title: "Éxito",
          text: "La solicitud fue enviada correctamente.",
        });
       
        setOrden("");
        setText("");
      } else {
       
        Swal.fire({
          icon: "error",
          title: "Orden no encontrada",
          text: "La orden ingresada no existe. Verifique e intente nuevamente.",
        });
      }
    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un problema al procesar la solicitud. Intente nuevamente.",
      });
    }
  };

  return (
    <Paper style={{ padding: "10px", marginBottom: "10px" }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <TextField
            margin="dense"
            label="Orden"
            type="text"
            fullWidth
            variant="outlined"
            name="Orden"
            value={orden}
            onChange={handleChangeOd}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <TextField
            margin="dense"
            label="Solicitud"
            type="text"
            fullWidth
            variant="outlined"
            name="Solicitud"
            value={text}
            onChange={handleChangeText}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Button
            variant="contained"
            size="medium"
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "#DA251C",
            }}
            onClick={handleEnviar}
          >
            Enviar Solicitud
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SolicitudModificacion;

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import { logout } from "../components/componentesRedux/userSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppBar, Toolbar, IconButton, Menu } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery, useTheme, Box } from "@mui/material";
import { useSelector } from "react-redux";
import MobileMenu from "./MobileMenu";
import DesktopMenu from "./DesktopMenu";

function Sidebar({ onDrawerOpenChange }) {
  const [openSubMenu, setOpenSubMenu] = React.useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(true);
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMenuAnchorEl);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const tipoUsuario = useSelector((state) => state.user.userInfo.tipo_usuario);

  const handleToggleSubmenu = (menu) => {
    if (openSubmenu === menu) {
      setOpenSubmenu(null);
    } else {
      setOpenSubmenu(menu);
    }
  };

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(logout());
    navigate("/login");
  };
  

  const handleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    onDrawerOpenChange(!isDrawerOpen);
  };

  const handleClick = (menu) => {
    if (openSubMenu === menu) {
      setOpenSubMenu(null);
    } else {
      setOpenSubMenu(menu);
    }
  };

  return (
    <div id="side-bar">
    {/* cuando es mobile */}
      {isMobile && (
        <>
          <AppBar position="fixed" sx={{ bgcolor: "#041562" }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={handleMobileMenuOpen}>
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Menu
            anchorEl={mobileMenuAnchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            keepMounted
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
          >
            <MobileMenu
              tipoUsuario={tipoUsuario}
              handleMobileMenuClose={handleMobileMenuClose}
              navigate={navigate}
              openSubmenu={openSubmenu}
              handleToggleSubmenu={handleToggleSubmenu}
              handleLogout={handleLogout}
            />
          </Menu>
        </>
      )}
    {/* cuando es escritorio */}
      {!isMobile && (
        <Box
          component="main"
          sx={{
            mt: isMobile ? `${theme.mixins.toolbar.minHeight + theme.spacing(2)}px` : 0,
          }}
        >
          <Drawer
            variant="permanent"
            anchor="left"
            sx={{
              overflowX: "hidden",
              width: isDrawerOpen ? 240 : 75,
              flexShrink: 0,
              "& .MuiDrawer-paper": { width: isDrawerOpen ? 240 : 75, backgroundColor: "#041562" },
              "& .MuiDrawer-paper::-webkit-scrollbar": {
                width: 8,
                height: 8,
              },
              "& .MuiDrawer-paper::-webkit-scrollbar-thumb": {
                background: "#ccc",
                borderRadius: 4,
              },
              "& .MuiDrawer-paper::-webkit-scrollbar-thumb:hover": {
                background: "#b3b3b3",
                boxShadow: "0 0 2px 1px rgba(0, 0, 0, 0.2)",
              },
              "& .MuiDrawer-paper::-webkit-scrollbar-thumb:active": {
                backgroundColor: "#999999",
              },
              "& .MuiDrawer-paper::-webkit-scrollbar-track": {
                background: "#e1e1e1",
                borderRadius: 4,
              },
              "& .MuiDrawer-paper::-webkit-scrollbar-track:hover, .MuiDrawer-paper::-webkit-scrollbar-track:active": {
                background: "#d4d4d4",
              },
            }}
          >
            <DesktopMenu
              tipoUsuario={tipoUsuario}
              isDrawerOpen={isDrawerOpen}
              handleClick={handleClick}
              handleDrawer={handleDrawer}
              openSubMenu={openSubMenu}
              location={location}
              handleLogout={handleLogout}
            />
          </Drawer>
        </Box>
      )}
    </div>
  );
}

export default Sidebar;


import React, { useState } from "react";
import { Paper, Grid, Button, Typography } from "@mui/material";
import CustomTextField from "../../utils/CustomTextField";
import { getInformeDiario } from "../../services/operacionesService";
import * as XLSX from "xlsx";

const ConsultaInformeCargaDiaria = () => {
  const [fecha, setFecha] = useState("");

  const handleChangeFecha = (e) => {
    setFecha(e.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await getInformeDiario(fecha);

      if (response && response.length > 0) {
        const rows = [];

        // Agrupar datos dinámicamente por TRANSPORTE
        const groupedData = response.reduce((acc, item) => {
          if (!acc[item.TRANSPORTE]) acc[item.TRANSPORTE] = [];
          acc[item.TRANSPORTE].push(item);
          return acc;
        }, {});

        // Generar filas dinámicamente
        Object.keys(groupedData).forEach((transporte) => {
          rows.push([transporte]); // Título del transporte
          rows.push(["CIUDAD", "DETALLE", "SACOS", "CAJAS", "PALLETS", "TOTAL", "NOTA"]); // Encabezado

          groupedData[transporte].forEach((item) => {
            const detalle = item.TIPO_CARGA === "NOVOFARMA" ? "NOVOFARMA" : item.AGENTE || "";
            rows.push([
              item.CIUDAD,
              detalle,
              item.SACOS || "",
              item.BULTOS || "",
              item.PALLETS || "",
              item.TOTAL || "",
              item.NOTA || "",
            ]);
          });

          rows.push([]); // Fila vacía entre transportes
        });

        // Crear hoja de trabajo y libro
        const worksheet = XLSX.utils.aoa_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Informe Carga Diaria");

        // Descargar archivo
        XLSX.writeFile(workbook, `Informe_Carga_${fecha}.xlsx`);
      } else {
        console.error("No hay datos para exportar");
      }
    } catch (error) {
      console.error("Error al obtener detalles", error);
    }
  };

  return (
    <Paper style={{ padding: "10px", marginBottom: "10px", marginTop: "20px" }}>
      <Typography variant="h6" align="center" gutterBottom>
        Búsqueda por Fecha
      </Typography>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <CustomTextField
            fullWidth
            type="date"
            size="small"
            label="Fecha Inicio"
            value={fecha}
            onChange={handleChangeFecha}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Button
            onClick={handleFormSubmit}
            fullWidth={true}
            sx={{
              backgroundColor: "#041562",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#031042",
              },
            }}
          >
            Buscar y Exportar
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ConsultaInformeCargaDiaria;

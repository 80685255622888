const ciudadesPrincipales = [
  // XV Región de Arica y Parinacota
  {
    name: "ARICA",
    tiempo: 24,
    tipo: "CIUDAD PRINCIPAL",
    lat: -18.4746,
    lng: -70.2979,
    ramales: [
      { name: "AZAPA", tiempo: 48, tipo: "RAMAL", lat: -18.4122, lng: -69.6261 },
      { name: "CAMARONES", tiempo: 72, tipo: "RAMAL", lat: -19.0114, lng: -69.8597 },
      { name: "PUTRE", tiempo: 72, tipo: "RAMAL", lat: -18.1982, lng: -69.5607 },
    ],
  },
  // I Región de Tarapacá
  {
    name: "IQUIQUE",
    tiempo: 24,
    tipo: "CIUDAD PRINCIPAL",
    lat: -20.2133,
    lng: -70.1503,
    ramales: [
      { name: "ALTO HOSPICIO", tiempo: 24, tipo: "RAMAL", lat: -20.2614, lng: -70.1033 },
      { name: "CAMIÑA", tiempo: 72, tipo: "RAMAL", lat: -19.3056, lng: -69.4333 },
      { name: "COLCHANE", tiempo: 72, tipo: "RAMAL", lat: -19.2694, lng: -68.6344 },
      { name: "HUARA", tiempo: 48, tipo: "RAMAL", lat: -19.9986, lng: -69.7697 },
      { name: "PICA", tiempo: 48, tipo: "RAMAL", lat: -20.4972, lng: -69.3275 },
      { name: "POZO AL MONTE", tiempo: 48, tipo: "RAMAL", lat: -20.2542, lng: -69.7861 },
    ],
  },
  // II Región de Antofagasta
  {
    name: "ANTOFAGASTA",
    tiempo: 24,
    tipo: "CIUDAD PRINCIPAL",
    lat: -23.65236,
    lng: -70.3954,
    ramales: [
      { name: "MARÍA ELENA", tiempo: 48, tipo: "RAMAL", lat: -22.35, lng: -69.67 },
      { name: "MEJILLONES", tiempo: 48, tipo: "RAMAL", lat: -23.10003, lng: -70.44703 },
      { name: "LA NEGRA", tiempo: 48, tipo: "RAMAL", lat: -24.056, lng: -70.445 },
      { name: "TALTAL", tiempo: 48, tipo: "RAMAL", lat: -25.4, lng: -70.48 },
      { name: "TOCOPILLA", tiempo: 48, tipo: "RAMAL", lat: -22.091, lng: -70.1979 },
    ],
  },
  // II Región de Antofagasta
  {
    name: "CALAMA",
    tiempo: 24,
    tipo: "CIUDAD PRINCIPAL",
    lat: -22.45667,
    lng: -68.92371,
    ramales: [
      { name: "BAQUEDANO", tiempo: 48, tipo: "RAMAL", lat: -23.267, lng: -69.75 },
      { name: "OLLAGÜE", tiempo: 48, tipo: "RAMAL", lat: -21.2167, lng: -68.25 },
      { name: "SAN PEDRO DE ATACAMA", tiempo: 48, tipo: "RAMAL", lat: -22.911, lng: -68.203 },
      { name: "SIERRA GORDA", tiempo: 48, tipo: "RAMAL", lat: -22.9, lng: -69.3167 },
    ],
  },
  // III Región de Atacama
  {
    name: "COPIAPÓ",
    tiempo: 24,
    tipo: "CIUDAD PRINCIPAL",
    lat: -27.3668,
    lng: -70.3322,
    ramales: [
      { name: "CALDERA", tiempo: 48, tipo: "RAMAL", lat: -27.0675, lng: -70.815 },
      { name: "CHAÑARAL", tiempo: 48, tipo: "RAMAL", lat: -26.342, lng: -70.621 },
      { name: "PAIPOTE", tiempo: 48, tipo: "RAMAL", lat: -27.3668, lng: -70.3322 },
      { name: "EL SALVADOR", tiempo: 48, tipo: "RAMAL", lat: -26.25, lng: -69.6667 },
      { name: "DIEGO DE ALMAGRO", tiempo: 48, tipo: "RAMAL", lat: -26.3667, lng: -70.05 },
      { name: "MINERA CANDELARIA", tiempo: 48, tipo: "RAMAL", lat: -27.7, lng: -70.2 },
      { name: "TIERRA AMARILLA", tiempo: 48, tipo: "RAMAL", lat: -27.4833, lng: -70.2833 },
    ],
  },
  // VALLENAR no tiene línea principal CIUDAD o COMUNA PRINCIPAL que coincida con VALLENAR
  // Por simplicidad, omitimos VALLENAR.
  // IV Región de Coquimbo
  {
    name: "LA SERENA",
    tiempo: 24,
    tipo: "CIUDAD PRINCIPAL",
    lat: -29.90453,
    lng: -71.24894,
    ramales: [
      { name: "ANDACOLLO", tiempo: 48, tipo: "RAMAL", lat: -30.23167, lng: -71.08528 },
      { name: "GUANAQUEROS", tiempo: 48, tipo: "RAMAL", lat: -30.25, lng: -71.43333 },
      { name: "LA HIGUERA", tiempo: 48, tipo: "RAMAL", lat: -29.5, lng: -71.2 },
      { name: "PAN DE AZÚCAR", tiempo: 48, tipo: "RAMAL", lat: -29.85, lng: -71.26667 },
      { name: "PAIHUANO", tiempo: 48, tipo: "RAMAL", lat: -30.03333, lng: -70.51667 },
      { name: "PISCO ELQUI", tiempo: 48, tipo: "RAMAL", lat: -30.23333, lng: -70.51667 },
      { name: "PERALILLO", tiempo: 48, tipo: "RAMAL", lat: -30.01667, lng: -70.48333 },
      { name: "TONGOY", tiempo: 48, tipo: "RAMAL", lat: -30.25, lng: -71.5 },
      { name: "VICUÑA", tiempo: 48, tipo: "RAMAL", lat: -30.03194, lng: -70.70833 },
    ],
  },
  {
    name: "SANTIAGO",
    tiempo: 24,
    tipo: "COMUNA PRINCIPAL",
    lat: -33.4489,
    lng: -70.6693,
    ramales: [
      { name: "ALHUÉ", tiempo: 48, tipo: "PERIFERIA", lat: -34.0456, lng: -71.1167 },
      { name: "BUIN", tiempo: 24, tipo: "PERIFERIA", lat: -33.7325, lng: -70.7428 },
      { name: "CALERA DE TANGO", tiempo: 24, tipo: "PERIFERIA", lat: -33.5833, lng: -70.7833 },
      { name: "CERRILLOS", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.4932, lng: -70.7146 },
      { name: "CERRO NAVIA", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.4167, lng: -70.7167 },
      { name: "COLINA", tiempo: 24, tipo: "PERIFERIA", lat: -33.2, lng: -70.6667 },
      { name: "CONCHALÍ", tiempo: 24, tipo: "PERIFERIA", lat: -33.3833, lng: -70.6667 },
      { name: "CURACAVÍ", tiempo: 24, tipo: "PERIFERIA", lat: -33.4, lng: -71.1167 },
      { name: "EL BOSQUE", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.55, lng: -70.6667 },
      { name: "EL MONTE", tiempo: 24, tipo: "PERIFERIA", lat: -33.6833, lng: -70.9833 },
      { name: "ESTACIÓN CENTRAL", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.4569, lng: -70.6833 },
      { name: "HUECHURABA", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.3667, lng: -70.6667 },
      { name: "INDEPENDENCIA", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.4167, lng: -70.65 },
      { name: "ISLA DE MAIPO", tiempo: 24, tipo: "PERIFERIA", lat: -33.7333, lng: -70.9 },
      { name: "LA CISTERNA", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.5333, lng: -70.6667 },
      { name: "LA DEHESA", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.35, lng: -70.5167 },
      { name: "LA FLORIDA", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.55, lng: -70.5833 },
      { name: "LA GRANJA", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.5333, lng: -70.6167 },
      { name: "LA PINTANA", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.5833, lng: -70.6167 },
      { name: "LA REINA", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.45, lng: -70.55 },
      { name: "LAMPA", tiempo: 24, tipo: "PERIFERIA", lat: -33.2833, lng: -70.8833 },
      { name: "LAS CONDES", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.4167, lng: -70.55 },
      { name: "LO BARNECHEA", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.35, lng: -70.5167 },
      { name: "LO ESPEJO", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.5167, lng: -70.6833 },
      { name: "LO PRADO", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.4333, lng: -70.7167 },
      { name: "MACUL", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.4833, lng: -70.5833 },
      { name: "MAIPÚ", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.4667, lng: -70.75 },
      { name: "MARÍA PINTO", tiempo: 24, tipo: "PERIFERIA", lat: -33.55, lng: -71.15 },
      { name: "MELIPILLA", tiempo: 24, tipo: "PERIFERIA", lat: -33.6833, lng: -71.2167 },
      { name: "ÑUÑOA", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.45, lng: -70.6 },
      { name: "PADRE HURTADO", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.55, lng: -70.8167 },
      { name: "PAINE", tiempo: 24, tipo: "PERIFERIA", lat: -33.8167, lng: -70.75 },
      { name: "PEDRO AGUIRRE CERDA", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.4833, lng: -70.6667 },
      { name: "PEÑAFLOR", tiempo: 24, tipo: "PERIFERIA", lat: -33.6167, lng: -70.9167 },
      { name: "PEÑALOLÉN", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.4833, lng: -70.55 },
      { name: "PIRQUE", tiempo: 24, tipo: "PERIFERIA", lat: -33.6167, lng: -70.5833 },
      { name: "PROVIDENCIA", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.4333, lng: -70.6167 },
      { name: "PUDAHUEL", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.4333, lng: -70.75 },
      { name: "PUENTE ALTO", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.6167, lng: -70.575 },
      { name: "QUILICURA", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.3667, lng: -70.7333 },
      { name: "QUINTA NORMAL", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.4333, lng: -70.7 },
      { name: "RECOLETA", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.4, lng: -70.65 },
      { name: "RENCA", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.4, lng: -70.7333 },
      { name: "SAN BERNARDO", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.5933, lng: -70.6989 },
      { name: "SAN JOAQUÍN", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.4889, lng: -70.6292 },
      { name: "SAN JOSÉ DE MAIPO", tiempo: 24, tipo: "PERIFERIA", lat: -33.6556, lng: -70.3517 },
      { name: "SAN MIGUEL", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.5014, lng: -70.6644 },
      { name: "SAN PEDRO", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.9167, lng: -71.4667 },
      { name: "SAN RAMÓN", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.5333, lng: -70.6333 },
      { name: "TALAGANTE", tiempo: 24, tipo: "PERIFERIA", lat: -33.6667, lng: -70.9333 },
      { name: "TILTIL", tiempo: 24, tipo: "PERIFERIA", lat: -33.0833, lng: -70.7333 },
      { name: "VITACURA", tiempo: 24, tipo: "COMUNA PRINCIPAL", lat: -33.3833, lng: -70.5833 },
    ],
  },
  {
    name: "VALPARAÍSO",
    tiempo: 24,
    tipo: "CIUDAD PRINCIPAL",
    lat: -33.036,
    lng: -71.6296,
    ramales: [
      { name: "ALGARROBO", tiempo: 48, tipo: "RAMAL", lat: -33.349, lng: -71.669 },
      { name: "CABILDO", tiempo: 48, tipo: "RAMAL", lat: -32.426, lng: -71.134 },
      { name: "CALLE LARGA", tiempo: 48, tipo: "RAMAL", lat: -32.855, lng: -70.626 },
      { name: "CARTAGENA", tiempo: 48, tipo: "RAMAL", lat: -33.552, lng: -71.607 },
      { name: "CASABLANCA", tiempo: 48, tipo: "RAMAL", lat: -33.321, lng: -71.407 },
      { name: "CATEMU", tiempo: 48, tipo: "RAMAL", lat: -32.778, lng: -70.941 },
      { name: "CONCÓN", tiempo: 48, tipo: "RAMAL", lat: -32.933, lng: -71.533 },
      { name: "EL BELLOTO", tiempo: 48, tipo: "RAMAL", lat: -33.045, lng: -71.442 },
      { name: "EL QUISCO", tiempo: 48, tipo: "RAMAL", lat: -33.398, lng: -71.698 },
      { name: "EL TABO", tiempo: 48, tipo: "RAMAL", lat: -33.443, lng: -71.667 },
      { name: "HIJUELAS", tiempo: 48, tipo: "RAMAL", lat: -32.744, lng: -71.165 },
      { name: "ISLA DE PASCUA", tiempo: 72, tipo: "RAMAL", lat: -27.112, lng: -109.349 },
      { name: "JUAN FERNÁNDEZ", tiempo: 96, tipo: "RAMAL", lat: -33.636, lng: -78.837 },
      { name: "LA CALERA", tiempo: 48, tipo: "RAMAL", lat: -32.788, lng: -71.196 },
      { name: "LA CRUZ", tiempo: 48, tipo: "RAMAL", lat: -32.825, lng: -71.223 },
      { name: "LA LIGUA", tiempo: 48, tipo: "RAMAL", lat: -32.452, lng: -71.231 },
      { name: "LIMACHE", tiempo: 48, tipo: "RAMAL", lat: -33.015, lng: -71.264 },
      { name: "LLAY LLAY", tiempo: 48, tipo: "RAMAL", lat: -32.843, lng: -70.956 },
      { name: "LOS ANDES", tiempo: 48, tipo: "RAMAL", lat: -32.833, lng: -70.598 },
      { name: "NOGALES", tiempo: 48, tipo: "RAMAL", lat: -32.733, lng: -71.233 },
      { name: "OLMUÉ", tiempo: 48, tipo: "RAMAL", lat: -32.996, lng: -71.183 },
      { name: "PANQUEHUE", tiempo: 48, tipo: "RAMAL", lat: -32.783, lng: -70.833 },
      { name: "PAPUDO", tiempo: 48, tipo: "RAMAL", lat: -32.512, lng: -71.448 },
      { name: "PETORCA", tiempo: 48, tipo: "RAMAL", lat: -32.253, lng: -70.933 },
      { name: "PUCHUNCAVÍ", tiempo: 48, tipo: "RAMAL", lat: -32.728, lng: -71.422 },
      { name: "PUTAENDO", tiempo: 48, tipo: "RAMAL", lat: -32.627, lng: -70.725 },
      { name: "QUILLOTA", tiempo: 48, tipo: "RAMAL", lat: -32.883, lng: -71.248 },
      { name: "QUILPUÉ", tiempo: 48, tipo: "RAMAL", lat: -33.048, lng: -71.442 },
      { name: "QUINTERO", tiempo: 48, tipo: "RAMAL", lat: -32.783, lng: -71.533 },
      { name: "RINCONADA", tiempo: 48, tipo: "RAMAL", lat: -32.833, lng: -70.716 },
      { name: "REÑACA", tiempo: 48, tipo: "RAMAL", lat: -32.95, lng: -71.533 },
      { name: "SAN ANTONIO", tiempo: 48, tipo: "RAMAL", lat: -33.593, lng: -71.621 },
      { name: "SAN ESTEBAN", tiempo: 48, tipo: "RAMAL", lat: -32.783, lng: -70.583 },
      { name: "SAN FELIPE", tiempo: 48, tipo: "RAMAL", lat: -32.75, lng: -70.726 },
      { name: "SANTA MARÍA", tiempo: 48, tipo: "RAMAL", lat: -32.75, lng: -70.683 },
      { name: "SANTO DOMINGO", tiempo: 48, tipo: "RAMAL", lat: -33.645, lng: -71.613 },
      { name: "VILLA ALEMANA", tiempo: 48, tipo: "RAMAL", lat: -33.042, lng: -71.373 },
      { name: "VIÑA DEL MAR", tiempo: 24, tipo: "RAMAL", lat: -33.015, lng: -71.549 },
      { name: "ZAPALLAR", tiempo: 48, tipo: "RAMAL", lat: -32.563, lng: -71.452 },
    ],
  },
  {
    name: "RANCAGUA",
    tiempo: 24,
    tipo: "CIUDAD PRINCIPAL",
    lat: -34.1708,
    lng: -70.7446,
    ramales: [
      { name: "CHEPICA", tiempo: 48, tipo: "RAMAL", lat: -34.7214, lng: -71.2836 },
      { name: "CHIMBARONGO", tiempo: 48, tipo: "RAMAL", lat: -34.7071, lng: -71.0436 },
      { name: "CODEGUA", tiempo: 48, tipo: "RAMAL", lat: -34.0333, lng: -70.7 },
      { name: "COINCO", tiempo: 48, tipo: "RAMAL", lat: -34.2833, lng: -70.95 },
      { name: "COLTAUCO", tiempo: 48, tipo: "RAMAL", lat: -34.3333, lng: -71.0333 },
      { name: "DOÑIHUE", tiempo: 48, tipo: "RAMAL", lat: -34.2167, lng: -70.9333 },
      { name: "GRANEROS", tiempo: 48, tipo: "RAMAL", lat: -34.0667, lng: -70.7167 },
      { name: "LA ESTRELLA", tiempo: 48, tipo: "RAMAL", lat: -34.1667, lng: -71.6667 },
      { name: "LAS CABRAS", tiempo: 48, tipo: "RAMAL", lat: -34.2833, lng: -71.2833 },
      { name: "LITUECHE", tiempo: 48, tipo: "RAMAL", lat: -34.1333, lng: -71.7667 },
      { name: "LOLOL", tiempo: 48, tipo: "RAMAL", lat: -34.7333, lng: -71.6333 },
      { name: "MACHALI", tiempo: 48, tipo: "RAMAL", lat: -34.1833, lng: -70.65 },
      { name: "MALLOA", tiempo: 48, tipo: "RAMAL", lat: -34.45, lng: -70.9333 },
      { name: "MARCHIHUE", tiempo: 48, tipo: "RAMAL", lat: -34.3833, lng: -71.6333 },
      { name: "SAN FCO. DE MOSTAZAL", tiempo: 48, tipo: "RAMAL", lat: -33.9833, lng: -70.7167 },
      { name: "NANCAGUA", tiempo: 48, tipo: "RAMAL", lat: -34.6667, lng: -71.1667 },
      { name: "NAVIDAD", tiempo: 48, tipo: "RAMAL", lat: -33.95, lng: -71.8333 },
      { name: "OLIVAR", tiempo: 48, tipo: "RAMAL", lat: -34.2167, lng: -70.8333 },
      { name: "PALMILLA", tiempo: 48, tipo: "RAMAL", lat: -34.6167, lng: -71.3667 },
      { name: "PAREDONES", tiempo: 48, tipo: "RAMAL", lat: -34.7333, lng: -71.8333 },
      { name: "PERALILLO", tiempo: 48, tipo: "RAMAL", lat: -34.6167, lng: -71.4667 },
      { name: "PEUMO", tiempo: 48, tipo: "RAMAL", lat: -34.3833, lng: -71.1667 },
      { name: "PICHIDEGUA", tiempo: 48, tipo: "RAMAL", lat: -34.3333, lng: -71.2833 },
      { name: "PICHILEMU", tiempo: 48, tipo: "RAMAL", lat: -34.3833, lng: -72.0 },
      { name: "PLACILLA", tiempo: 48, tipo: "RAMAL", lat: -34.6333, lng: -71.3667 },
      { name: "PUMANQUE", tiempo: 48, tipo: "RAMAL", lat: -34.6167, lng: -71.7667 },
      { name: "QUINTA DE TILCOCO", tiempo: 48, tipo: "RAMAL", lat: -34.3667, lng: -70.9667 },
      { name: "RENGO", tiempo: 48, tipo: "RAMAL", lat: -34.4167, lng: -70.8667 },
      { name: "REQUINOA", tiempo: 48, tipo: "RAMAL", lat: -34.2833, lng: -70.8167 },
      { name: "SAN FERNANDO", tiempo: 48, tipo: "RAMAL", lat: -34.5833, lng: -70.9833 },
      { name: "SAN VICENTE DE T.T.", tiempo: 48, tipo: "RAMAL", lat: -34.4333, lng: -71.0833 },
      { name: "SANTA CRUZ", tiempo: 48, tipo: "RAMAL", lat: -34.6333, lng: -71.3667 },
    ],
  },
  {
    name: "CURICÓ",
    tiempo: 24,
    tipo: "CIUDAD PRINCIPAL",
    lat: -34.98279,
    lng: -71.23943,
    ramales: [
      {
        name: "HUALAÑÉ",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -34.9708,
        lng: -71.7936,
      },
      {
        name: "LICANTÉN",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -34.9833,
        lng: -72.0,
      },
      {
        name: "LONTUÉ",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -35.0833,
        lng: -71.2833,
      },
      {
        name: "LOS NICHES",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -35.1,
        lng: -71.1333,
      },
      {
        name: "MOLINA",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -35.1167,
        lng: -71.2833,
      },
      {
        name: "RAUCO",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -34.9333,
        lng: -71.3667,
      },
      {
        name: "ROMERAL",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -34.9833,
        lng: -71.1833,
      },
      {
        name: "SAGRADA FAMILIA",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -34.95,
        lng: -71.6,
      },
      {
        name: "SARMIENTO",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -34.9667,
        lng: -71.2167,
      },
      {
        name: "TENO",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -34.8667,
        lng: -71.1667,
      },
      {
        name: "VICHUQUÉN",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -34.8833,
        lng: -72.0167,
      },
    ],
  },
  // VI Region del Libertador Bernardo O'Higgins
  // RANCAGUA CIUDAD PRINCIPAL
  {
    name: "RANCAGUA",
    tiempo: 24,
    tipo: "CIUDAD PRINCIPAL",
    lat: -34.1708,
    lng: -70.7446,
    ramales: [
      { name: "CHEPICA", tiempo: 48, tipo: "RAMAL", lat: -34.7214, lng: -71.2836 },
      { name: "CHIMBARONGO", tiempo: 48, tipo: "RAMAL", lat: -34.7071, lng: -71.0436 },
      { name: "CODEGUA", tiempo: 48, tipo: "RAMAL", lat: -34.0333, lng: -70.7 },
      { name: "COINCO", tiempo: 48, tipo: "RAMAL", lat: -34.2833, lng: -70.95 },
      { name: "COLTAUCO", tiempo: 48, tipo: "RAMAL", lat: -34.3333, lng: -71.0333 },
      { name: "DOÑIHUE", tiempo: 48, tipo: "RAMAL", lat: -34.2167, lng: -70.9333 },
      { name: "GRANEROS", tiempo: 48, tipo: "RAMAL", lat: -34.0667, lng: -70.7167 },
      { name: "LA ESTRELLA", tiempo: 48, tipo: "RAMAL", lat: -34.1667, lng: -71.6667 },
      { name: "LAS CABRAS", tiempo: 48, tipo: "RAMAL", lat: -34.2833, lng: -71.2833 },
      { name: "LITUECHE", tiempo: 48, tipo: "RAMAL", lat: -34.1333, lng: -71.7667 },
      { name: "LOLOL", tiempo: 48, tipo: "RAMAL", lat: -34.7333, lng: -71.6333 },
      { name: "MACHALI", tiempo: 48, tipo: "RAMAL", lat: -34.1833, lng: -70.65 },
      { name: "MALLOA", tiempo: 48, tipo: "RAMAL", lat: -34.45, lng: -70.9333 },
      { name: "MARCHIHUE", tiempo: 48, tipo: "RAMAL", lat: -34.3833, lng: -71.6333 },
      { name: "SAN FCO. DE MOSTAZAL", tiempo: 48, tipo: "RAMAL", lat: -33.9833, lng: -70.7167 },
      { name: "NANCAGUA", tiempo: 48, tipo: "RAMAL", lat: -34.6667, lng: -71.1667 },
      { name: "NAVIDAD", tiempo: 48, tipo: "RAMAL", lat: -33.95, lng: -71.8333 },
      { name: "OLIVAR", tiempo: 48, tipo: "RAMAL", lat: -34.2167, lng: -70.8333 },
      { name: "PALMILLA", tiempo: 48, tipo: "RAMAL", lat: -34.6167, lng: -71.3667 },
      { name: "PAREDONES", tiempo: 48, tipo: "RAMAL", lat: -34.7333, lng: -71.8333 },
      { name: "PERALILLO", tiempo: 48, tipo: "RAMAL", lat: -34.6167, lng: -71.4667 },
      { name: "PEUMO", tiempo: 48, tipo: "RAMAL", lat: -34.3833, lng: -71.1667 },
      { name: "PICHIDEGUA", tiempo: 48, tipo: "RAMAL", lat: -34.3333, lng: -71.2833 },
      { name: "PICHILEMU", tiempo: 48, tipo: "RAMAL", lat: -34.3833, lng: -72.0 },
      { name: "PLACILLA", tiempo: 48, tipo: "RAMAL", lat: -34.6333, lng: -71.3667 },
      { name: "PUMANQUE", tiempo: 48, tipo: "RAMAL", lat: -34.6167, lng: -71.7667 },
      { name: "QUINTA DE TILCOCO", tiempo: 48, tipo: "RAMAL", lat: -34.3667, lng: -70.9667 },
      { name: "RENGO", tiempo: 48, tipo: "RAMAL", lat: -34.4167, lng: -70.8667 },
      { name: "REQUINOA", tiempo: 48, tipo: "RAMAL", lat: -34.2833, lng: -70.8167 },
      { name: "SAN FERNANDO", tiempo: 48, tipo: "RAMAL", lat: -34.5833, lng: -70.9833 },
      { name: "SAN VICENTE DE T.T.", tiempo: 48, tipo: "RAMAL", lat: -34.4333, lng: -71.0833 },
      { name: "SANTA CRUZ", tiempo: 48, tipo: "RAMAL", lat: -34.6333, lng: -71.3667 },
    ],
  },
  {
    name: "LINARES",
    tiempo: 24,
    tipo: "CIUDAD PRINCIPAL",
    lat: -35.8466,
    lng: -71.5931,
    ramales: [
      { name: "LONGAVÍ", tiempo: 48, tipo: "RAMAL", lat: -35.967, lng: -71.6846 },
      { name: "RETIRO", tiempo: 48, tipo: "RAMAL", lat: -36.0511, lng: -71.7832 },
      { name: "PARRAL", tiempo: 48, tipo: "RAMAL", lat: -36.1385, lng: -71.8234 },
      { name: "YERBAS BUENAS", tiempo: 48, tipo: "RAMAL", lat: -35.7873, lng: -71.5893 },
    ],
  },
  {
    name: "TALCA",
    tiempo: 24,
    tipo: "CIUDAD PRINCIPAL",
    lat: -35.4264,
    lng: -71.6554,
    ramales: [
      { name: "CAUQUENES", tiempo: 48, tipo: "RAMAL", lat: -35.9608, lng: -72.322 },
      { name: "CHANCO", tiempo: 48, tipo: "RAMAL", lat: -35.7328, lng: -72.5376 },
      { name: "COLBÚN", tiempo: 48, tipo: "RAMAL", lat: -35.6956, lng: -71.3926 },
      { name: "CONSTITUCIÓN", tiempo: 48, tipo: "RAMAL", lat: -35.3333, lng: -72.4167 },
      { name: "CORINTO", tiempo: 48, tipo: "RAMAL", lat: -35.9375, lng: -72.036 },
      { name: "CUREPTO", tiempo: 48, tipo: "RAMAL", lat: -35.0833, lng: -72.0167 },
      { name: "CURANIPE", tiempo: 48, tipo: "RAMAL", lat: -35.8488, lng: -72.5807 },
      { name: "EMPEDRADO", tiempo: 48, tipo: "RAMAL", lat: -35.5667, lng: -72.1167 },
      { name: "MAULE", tiempo: 48, tipo: "RAMAL", lat: -35.4948, lng: -71.6667 },
      { name: "PELARCO", tiempo: 48, tipo: "RAMAL", lat: -35.3704, lng: -71.4203 },
      { name: "PELLUHUE", tiempo: 48, tipo: "RAMAL", lat: -35.8131, lng: -72.5814 },
      { name: "PENCAHUE", tiempo: 48, tipo: "RAMAL", lat: -35.394, lng: -71.8591 },
      { name: "RIO CLARO", tiempo: 48, tipo: "RAMAL", lat: -35.4465, lng: -71.2845 },
      { name: "SAN CLEMENTE", tiempo: 48, tipo: "RAMAL", lat: -35.54, lng: -71.4864 },
      { name: "SAN JAVIER", tiempo: 48, tipo: "RAMAL", lat: -35.5966, lng: -71.7296 },
      { name: "SAN RAFAEL", tiempo: 48, tipo: "RAMAL", lat: -35.3611, lng: -71.6136 },
      { name: "VILLA ALEGRE", tiempo: 48, tipo: "RAMAL", lat: -35.675, lng: -71.7356 },
    ],
  },
  // XVI Region del Ñuble (CHILLAN CIUDAD PRINCIPAL)
  {
    name: "CHILLÁN",
    tiempo: 24,
    tipo: "CIUDAD PRINCIPAL",
    lat: -36.6066,
    lng: -72.1034,
    ramales: [
      { name: "BULNES", tiempo: 48, tipo: "RAMAL", lat: -36.7445, lng: -72.2973 },
      { name: "CHILLÁN VIEJO", tiempo: 24, tipo: "RAMAL", lat: -36.6552, lng: -72.123 },
      { name: "COBQUECURA", tiempo: 48, tipo: "RAMAL", lat: -36.1386, lng: -72.7865 },
      { name: "COELEMU", tiempo: 48, tipo: "RAMAL", lat: -36.4854, lng: -72.6982 },
      { name: "COIHUECO", tiempo: 48, tipo: "RAMAL", lat: -36.6233, lng: -71.8317 },
      { name: "EL CARMEN", tiempo: 48, tipo: "RAMAL", lat: -36.8902, lng: -72.0374 },
      { name: "NINHUE", tiempo: 48, tipo: "RAMAL", lat: -36.2968, lng: -72.4227 },
      { name: "ÑIQUÉN", tiempo: 48, tipo: "RAMAL", lat: -36.2814, lng: -71.975 },
      { name: "PEMUCO", tiempo: 48, tipo: "RAMAL", lat: -36.9789, lng: -72.0976 },
      { name: "PINTO", tiempo: 48, tipo: "RAMAL", lat: -36.7476, lng: -71.9028 },
      { name: "PORTEZUELO", tiempo: 48, tipo: "RAMAL", lat: -36.5331, lng: -72.4272 },
      { name: "QUILLÓN", tiempo: 48, tipo: "RAMAL", lat: -36.7297, lng: -72.4692 },
      { name: "QUIRIHUE", tiempo: 48, tipo: "RAMAL", lat: -36.281, lng: -72.5377 },
      { name: "RÁNQUIL", tiempo: 48, tipo: "RAMAL", lat: -36.5186, lng: -72.5189 },
      { name: "SAN CARLOS", tiempo: 48, tipo: "RAMAL", lat: -36.4265, lng: -71.9583 },
      { name: "SAN FABIÁN", tiempo: 48, tipo: "RAMAL", lat: -36.5578, lng: -71.5744 },
      { name: "SAN IGNACIO", tiempo: 48, tipo: "RAMAL", lat: -36.8165, lng: -72.0336 },
      { name: "SAN NICOLÁS", tiempo: 48, tipo: "RAMAL", lat: -36.5357, lng: -72.0741 },
      { name: "TANILVORO", tiempo: 48, tipo: "RAMAL", lat: -36.71, lng: -71.9 },
      { name: "TREHUACO", tiempo: 48, tipo: "RAMAL", lat: -36.4634, lng: -72.6674 },
      { name: "YUNGAY", tiempo: 48, tipo: "RAMAL", lat: -37.0927, lng: -71.9294 },
    ],
  },
  // VIII Region del Bio Bio (LOS ANGELES y CONCEPCION CIUDAD PRINCIPAL)
  {
    name: "LOS ANGELES",
    tiempo: 24,
    tipo: "CIUDAD PRINCIPAL",
    lat: -37.46973,
    lng: -72.35366,
    ramales: [
      { name: "ALTO BIO BIO", tiempo: 48, tipo: "RAMAL", lat: -37.6764, lng: -71.7304 },
      { name: "ANGOL", tiempo: 48, tipo: "RAMAL", lat: -37.7952, lng: -72.7164 },
      { name: "ANTUCO", tiempo: 48, tipo: "RAMAL", lat: -37.2958, lng: -71.6764 },
      { name: "CABRERO", tiempo: 48, tipo: "RAMAL", lat: -37.0333, lng: -72.4 },
      { name: "COLLIPULLI", tiempo: 48, tipo: "RAMAL", lat: -37.9547, lng: -72.4353 },
      { name: "HUEPIL", tiempo: 48, tipo: "RAMAL", lat: -37.2167, lng: -72.0167 },
      { name: "LOS SAUCES", tiempo: 48, tipo: "RAMAL", lat: -37.9722, lng: -72.8333 },
      { name: "MININCO", tiempo: 48, tipo: "RAMAL", lat: -37.9167, lng: -72.4333 },
      { name: "MULCHEN", tiempo: 48, tipo: "RAMAL", lat: -37.717, lng: -72.233 },
      { name: "NACIMIENTO", tiempo: 48, tipo: "RAMAL", lat: -37.5, lng: -72.6667 },
      { name: "NEGRETE", tiempo: 48, tipo: "RAMAL", lat: -37.5833, lng: -72.5333 },
      { name: "PUREN", tiempo: 48, tipo: "RAMAL", lat: -38.0333, lng: -73.0833 },
      { name: "QUILACO", tiempo: 48, tipo: "RAMAL", lat: -37.6833, lng: -71.9833 },
      { name: "QUILLECO", tiempo: 48, tipo: "RAMAL", lat: -37.4667, lng: -71.9333 },
      { name: "RALCO", tiempo: 48, tipo: "RAMAL", lat: -37.6833, lng: -71.5333 },
      { name: "RENAICO", tiempo: 48, tipo: "RAMAL", lat: -37.6833, lng: -72.5833 },
      { name: "MONTE AGUILA", tiempo: 48, tipo: "RAMAL", lat: -37.05, lng: -72.35 },
      { name: "SAN ROSENDO", tiempo: 48, tipo: "RAMAL", lat: -37.2833, lng: -72.7167 },
      { name: "SANTA BARBARA", tiempo: 48, tipo: "RAMAL", lat: -37.6667, lng: -72.0167 },
      { name: "SANTA FE", tiempo: 48, tipo: "RAMAL", lat: -37.45, lng: -72.7 },
      { name: "TUCAPEL", tiempo: 48, tipo: "RAMAL", lat: -37.2833, lng: -71.9667 },
      { name: "YUMBEL", tiempo: 48, tipo: "RAMAL", lat: -37.0833, lng: -72.5667 },
    ],
  },
  {
    name: "CONCEPCION",
    tiempo: 24,
    tipo: "CIUDAD PRINCIPAL",
    lat: -36.82699,
    lng: -73.04977,
    ramales: [
      { name: "ARAUCO", tiempo: 48, tipo: "RAMAL", lat: -37.2467, lng: -73.3175 },
      { name: "CAÑETE", tiempo: 48, tipo: "RAMAL", lat: -37.8033, lng: -73.3986 },
      { name: "CHIGUAYANTE", tiempo: 48, tipo: "RAMAL", lat: -36.9167, lng: -73.0167 },
      { name: "CONTULMO", tiempo: 48, tipo: "RAMAL", lat: -38.0167, lng: -73.2167 },
      { name: "CORONEL", tiempo: 48, tipo: "RAMAL", lat: -37.0333, lng: -73.1333 },
      { name: "CURANILAHUE", tiempo: 48, tipo: "RAMAL", lat: -37.4778, lng: -73.3458 },
      { name: "DICHATO", tiempo: 48, tipo: "RAMAL", lat: -36.5456, lng: -72.9425 },
      { name: "FLORIDA", tiempo: 48, tipo: "RAMAL", lat: -36.8167, lng: -72.6667 },
      { name: "HUALPEN", tiempo: 48, tipo: "RAMAL", lat: -36.7833, lng: -73.1167 },
      { name: "HUALQUI", tiempo: 48, tipo: "RAMAL", lat: -36.95, lng: -72.95 },
      { name: "LEBU", tiempo: 48, tipo: "RAMAL", lat: -37.6167, lng: -73.6667 },
      { name: "LIRQUEN", tiempo: 48, tipo: "RAMAL", lat: -36.7167, lng: -72.9833 },
      { name: "LOS ALAMOS", tiempo: 48, tipo: "RAMAL", lat: -37.6167, lng: -73.4667 },
      { name: "LOTA", tiempo: 48, tipo: "RAMAL", lat: -37.0833, lng: -73.1667 },
      { name: "PENCO", tiempo: 48, tipo: "RAMAL", lat: -36.7333, lng: -72.9833 },
      { name: "SAN PEDRO DE LA PAZ", tiempo: 48, tipo: "RAMAL", lat: -36.8333, lng: -73.1167 },
      { name: "SANTA JUANA", tiempo: 48, tipo: "RAMAL", lat: -37.1667, lng: -72.9333 },
      { name: "TALCAHUANO", tiempo: 24, tipo: "CIUDAD PRINCIPAL", lat: -36.7167, lng: -73.1167 },
      { name: "TIRUA", tiempo: 48, tipo: "RAMAL", lat: -38.3333, lng: -73.4833 },
      { name: "TOME", tiempo: 48, tipo: "RAMAL", lat: -36.6167, lng: -72.95 },
    ],
  },
  // IX Region de La Araucania (TEMUCO CIUDAD PRINCIPAL)
  {
    name: "TEMUCO",
    tiempo: 24,
    tipo: "CIUDAD PRINCIPAL",
    lat: -38.73965,
    lng: -72.59842,
    ramales: [
      { name: "CARAHUE", tiempo: 48, tipo: "RAMAL", lat: -38.7117, lng: -73.1677 },
      { name: "CHOLCHOL", tiempo: 48, tipo: "RAMAL", lat: -38.6017, lng: -72.8444 },
      { name: "CUNCO", tiempo: 48, tipo: "RAMAL", lat: -38.9328, lng: -72.0328 },
      { name: "CURACAUTÍN", tiempo: 48, tipo: "RAMAL", lat: -38.4367, lng: -71.8878 },
      { name: "CURARREHUE", tiempo: 48, tipo: "RAMAL", lat: -39.2767, lng: -71.5667 },
      { name: "ERCILLA", tiempo: 48, tipo: "RAMAL", lat: -38.0497, lng: -72.3983 },
      { name: "FREIRE", tiempo: 48, tipo: "RAMAL", lat: -38.9533, lng: -72.62 },
      { name: "GALVARINO", tiempo: 48, tipo: "RAMAL", lat: -38.4167, lng: -72.7833 },
      { name: "GORBEA", tiempo: 48, tipo: "RAMAL", lat: -39.0933, lng: -72.6794 },
      { name: "LABRANZA", tiempo: 48, tipo: "RAMAL", lat: -38.7167, lng: -72.6667 },
      { name: "LAUTARO", tiempo: 48, tipo: "RAMAL", lat: -38.52, lng: -72.4472 },
      { name: "LICÁN RAY", tiempo: 48, tipo: "RAMAL", lat: -39.4933, lng: -72.2183 },
      { name: "LONCOCHE", tiempo: 48, tipo: "RAMAL", lat: -39.3667, lng: -72.6333 },
      { name: "LONQUIMAY", tiempo: 48, tipo: "RAMAL", lat: -38.4333, lng: -71.2333 },
      { name: "LUMACO", tiempo: 48, tipo: "RAMAL", lat: -38.25, lng: -72.95 },
      { name: "MELIPEUCO", tiempo: 48, tipo: "RAMAL", lat: -38.85, lng: -71.7 },
      { name: "NUEVA IMPERIAL", tiempo: 48, tipo: "RAMAL", lat: -38.7444, lng: -72.95 },
      { name: "PADRE LAS CASAS", tiempo: 48, tipo: "RAMAL", lat: -38.7667, lng: -72.6 },
      { name: "PUERTO SAAVEDRA", tiempo: 48, tipo: "RAMAL", lat: -38.7833, lng: -73.4 },
      { name: "PERQUENCO", tiempo: 48, tipo: "RAMAL", lat: -38.4167, lng: -72.3833 },
      { name: "PITRUFQUÉN", tiempo: 48, tipo: "RAMAL", lat: -38.9872, lng: -72.6428 },
      { name: "PUCÓN", tiempo: 48, tipo: "RAMAL", lat: -39.2708, lng: -71.9792 },
      { name: "TEODORO SCHMIDT", tiempo: 48, tipo: "RAMAL", lat: -38.9783, lng: -73.2028 },
      { name: "TOLTÉN", tiempo: 48, tipo: "RAMAL", lat: -39.2167, lng: -73.2333 },
      { name: "QUEPE", tiempo: 48, tipo: "RAMAL", lat: -38.7667, lng: -72.55 },
      { name: "TRAIGUÉN", tiempo: 48, tipo: "RAMAL", lat: -38.25, lng: -72.6833 },
      { name: "VICTORIA", tiempo: 48, tipo: "RAMAL", lat: -38.2333, lng: -72.3333 },
      { name: "VILCÚN", tiempo: 48, tipo: "RAMAL", lat: -38.65, lng: -72.23 },
      { name: "VILLARRICA", tiempo: 48, tipo: "RAMAL", lat: -39.2856, lng: -72.2278 },
    ],
  },
  // XIV Region de Los Rios (VALDIVIA CIUDAD PRINCIPAL)
  {
    name: "VALDIVIA",
    tiempo: 24,
    tipo: "CIUDAD PRINCIPAL",
    lat: -39.819588,
    lng: -73.245209,
    ramales: [
      {
        name: "CORRAL",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -39.8861,
        lng: -73.4272,
      },
      {
        name: "FUTRONO",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -40.1317,
        lng: -72.3875,
      },
      {
        name: "LANCO",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -39.4531,
        lng: -72.7756,
      },
      {
        name: "LOS LAGOS",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -39.8467,
        lng: -72.8311,
      },
      {
        name: "MAFIL",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -39.6542,
        lng: -72.9567,
      },
      {
        name: "MARIQUINA",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -39.5261,
        lng: -72.7825,
      },
      {
        name: "PAILLACO",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -40.0667,
        lng: -72.8733,
      },
      {
        name: "PANGUIPULLI",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -39.6433,
        lng: -72.3325,
      },
      {
        name: "MALALHUE",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -39.7283,
        lng: -72.4389,
      },
      {
        name: "NIEBLA",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -39.8683,
        lng: -73.3983,
      },
    ],
  },
  // X Region de Los Lagos (OSORNO, PUERTO MONTT, CASTRO con CIUDAD PRINCIPAL)
  {
    name: "OSORNO",
    tiempo: 24,
    tipo: "CIUDAD PRINCIPAL",
    lat: -40.57395,
    lng: -73.13348,
    ramales: [
      {
        name: "LA UNION",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -40.29313,
        lng: -73.08167,
      },
      {
        name: "LAGO RANCO",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -40.321,
        lng: -72.528,
      },
      {
        name: "LAFQUELMAPU",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -40.5,
        lng: -73.0,
      },
      {
        name: "PUAUCHO",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -40.4167,
        lng: -73.6667,
      },
      {
        name: "PUERTO OCTAY",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -40.97,
        lng: -72.883,
      },
      {
        name: "PURRANQUE",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -40.9167,
        lng: -73.1667,
      },
      {
        name: "PUYEHUE",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -40.6833,
        lng: -72.6167,
      },
      {
        name: "INIA REMEHUE",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -40.57,
        lng: -73.13,
      },
      {
        name: "ENTRE LAGOS",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -40.7667,
        lng: -72.6167,
      },
      {
        name: "QUILACAHUIN",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -40.5667,
        lng: -73.2,
      },
      {
        name: "RIO BUENO",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -40.3333,
        lng: -72.9667,
      },
      {
        name: "RIO NEGRO",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -40.7833,
        lng: -73.2333,
      },
      {
        name: "TRUMAO",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -40.3667,
        lng: -73.0667,
      },
      {
        name: "SAN JUAN DE LA COSTA",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -40.5167,
        lng: -73.7333,
      },
      {
        name: "SAN PABLO",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -40.3833,
        lng: -73.0167,
      },
    ],
  },
  {
    name: "PUERTO MONTT",
    tiempo: 24,
    tipo: "CIUDAD PRINCIPAL",
    lat: -41.4717,
    lng: -72.9361,
    ramales: [
      { name: "CALBUCO", tiempo: 48, tipo: "RAMAL", lat: -41.7667, lng: -73.1333 },
      { name: "CARELMAPU", tiempo: 48, tipo: "RAMAL", lat: -41.7667, lng: -73.4667 },
      { name: "CHINQUIHUE", tiempo: 48, tipo: "RAMAL", lat: -41.4833, lng: -73.0167 },
      { name: "CHAITÉN", tiempo: 72, tipo: "RAMAL", lat: -42.9167, lng: -72.7167 },
      { name: "COCHAMÓ", tiempo: 48, tipo: "RAMAL", lat: -41.4919, lng: -72.3042 },
      { name: "COLACO", tiempo: 48, tipo: "RAMAL", lat: -41.9833, lng: -73.1667 },
      { name: "FRESIA", tiempo: 48, tipo: "RAMAL", lat: -41.15, lng: -73.5 },
      { name: "FRUTILLAR", tiempo: 48, tipo: "RAMAL", lat: -41.1333, lng: -73.0167 },
      { name: "ALERCE", tiempo: 48, tipo: "RAMAL", lat: -41.35, lng: -72.8833 },
      { name: "FUTALEUFÚ", tiempo: 72, tipo: "RAMAL", lat: -43.1917, lng: -71.8681 },
      { name: "RÍO PUELO", tiempo: 72, tipo: "RAMAL", lat: -41.6425, lng: -72.32 },
      { name: "HUALAIHUÉ", tiempo: 48, tipo: "RAMAL", lat: -42.0186, lng: -72.6822 },
      { name: "LLANQUIHUE", tiempo: 48, tipo: "RAMAL", lat: -41.2544, lng: -73.0139 },
      { name: "LOS MUERMOS", tiempo: 48, tipo: "RAMAL", lat: -41.4, lng: -73.4667 },
      { name: "MAULLÍN", tiempo: 48, tipo: "RAMAL", lat: -41.6167, lng: -73.6 },
      { name: "MELINKA", tiempo: 96, tipo: "RAMAL", lat: -43.8833, lng: -73.7333 },
      { name: "HORNOPIRÉN", tiempo: 72, tipo: "RAMAL", lat: -42.3333, lng: -72.4667 },
      { name: "PARGUA", tiempo: 72, tipo: "RAMAL", lat: -41.85, lng: -73.3833 },
      { name: "PALENA", tiempo: 72, tipo: "RAMAL", lat: -43.6167, lng: -71.8 },
      { name: "PUERTO VARAS", tiempo: 24, tipo: "RAMAL", lat: -41.3167, lng: -72.9833 },
      { name: "QUILLAIPE", tiempo: 48, tipo: "RAMAL", lat: -41.45, lng: -72.9 },
    ],
  },
  // CASTRO no es CIUDAD PRINCIPAL según la tabla, sólo RAMAL. Aparece "CASTRO 48 RAMAL".
  // ACHA0 72 RAMAL, etc. No se ve "CASTRO" como CIUDAD PRINCIPAL. Omitimos.
  {
    name: "COYHAIQUE",
    tiempo: 24,
    tipo: "CIUDAD PRINCIPAL",
    lat: -45.57524,
    lng: -72.06619,
    ramales: [
      {
        name: "CHILE CHICO",
        tiempo: 96,
        tipo: "RAMAL",
        lat: -46.5416,
        lng: -71.7306,
      },
      {
        name: "PUERTO CISNES",
        tiempo: 96,
        tipo: "RAMAL",
        lat: -44.7486,
        lng: -72.7,
      },
      {
        name: "CISNES",
        tiempo: 96,
        tipo: "RAMAL",
        lat: -44.75,
        lng: -72.7,
      },
      {
        name: "COCHRANE",
        tiempo: 96,
        tipo: "RAMAL",
        lat: -47.254,
        lng: -72.5736,
      },
      {
        name: "GUAITECAS",
        tiempo: 96,
        tipo: "RAMAL",
        lat: -43.8833,
        lng: -73.7333,
      },
      {
        name: "LAGO VERDE",
        tiempo: 96,
        tipo: "RAMAL",
        lat: -44.65,
        lng: -71.8333,
      },
      {
        name: "OHIGGINS",
        tiempo: 96,
        tipo: "RAMAL",
        lat: -48.467,
        lng: -72.55,
      },
      {
        name: "PUERTO AYSEN",
        tiempo: 48,
        tipo: "RAMAL",
        lat: -45.403,
        lng: -72.6918,
      },
      {
        name: "PUERTO CHACABUCO",
        tiempo: 96,
        tipo: "RAMAL",
        lat: -45.46,
        lng: -72.82,
      },
      {
        name: "RIO IBAÑEZ",
        tiempo: 96,
        tipo: "RAMAL",
        lat: -46.2833,
        lng: -71.9333,
      },
      {
        name: "TORTEL",
        tiempo: 96,
        tipo: "RAMAL",
        lat: -47.7967,
        lng: -73.5333,
      },
    ],
  },
  // XII Region de Magallanes (PUNTA ARENAS CIUDAD PRINCIPAL)
  {
    name: "PUNTA ARENAS",
    tiempo: 24,
    tipo: "CIUDAD PRINCIPAL",
    lat: -53.1548,
    lng: -70.9113,
    ramales: [
      { name: "CABO DE HORNOS", tiempo: 96, tipo: "RAMAL", lat: -55.05, lng: -67.6833 },
      { name: "PUERTO NATALES", tiempo: 48, tipo: "RAMAL", lat: -51.7317, lng: -72.4875 },
      { name: "PORVENIR", tiempo: 48, tipo: "RAMAL", lat: -53.2975, lng: -70.3706 },
      { name: "PUERTO WILLIAMS", tiempo: 48, tipo: "RAMAL", lat: -55.05, lng: -67.6167 },
      { name: "SAN GREGORIO", tiempo: 48, tipo: "RAMAL", lat: -52.3167, lng: -69.6167 },
      { name: "TIMAUKEL", tiempo: 96, tipo: "RAMAL", lat: -54.167, lng: -69.6167 },
      { name: "TORRES DEL PAINE", tiempo: 96, tipo: "RAMAL", lat: -51.2569, lng: -72.9485 },
    ],
  },
];

export default ciudadesPrincipales;

import {
  toastCabecera,
  //fechaMenor,
  //fechaActual,
  sinFecha,
  sinCliente,
} from "./Alertas";
import { obtenerCiudadesHomologadas } from "../../../services/RetirosService";
import Backdrop from "@mui/material/Backdrop";
import { RedTransparentBackground } from "./Progress";

export const obtenerFechaActualFormateada = () => {
  const fechaActual = new Date();

  const year = fechaActual.getFullYear();
  const month = String(fechaActual.getMonth()).padStart(2, "0");
  const day = String(fechaActual.getDate()).padStart(2, "0");

  const fechaFormateada = `${year}-${month}-${day}`;

  return fechaFormateada;
};

export const limiteDeFilas = (row) => {
  for (const column of Object.values(row)) {
    if (column) {
      return true;
    }
  }
  return false;
};

export const ValidadorArchivo = (headersMatch, fechaRetiros) => {
  const condition2 = fechaRetiros !== undefined;
  //const condition4 = fechaRetiros < obtenerFechaActualFormateada();
  const condition5 = headersMatch;

  if (!condition2) {
    return sinFecha();
  /* } else if (condition4) {
    return fechaMenor(); */
  } else if (!condition5) {
    return toastCabecera();
  } else {
    return true;
  }
};

export const ValidarHomologacionCiudades = async (comuna, arrayComunasInvalidas) => {
  try {
    await obtenerCiudadesHomologadas(comuna);
  } catch (error) {
    if (!arrayComunasInvalidas.includes(comuna)) {
      arrayComunasInvalidas.push(comuna);
    }
  }
};

export const ValidadorArchivoAdmin = (headersMatch, codigo, fechaRetiros, codigoCliente) => {
  const condition2 = fechaRetiros !== undefined;
  const condition3 = codigo !== "";
  //const condition4 = fechaRetiros < obtenerFechaActualFormateada();
  const condition5 = headersMatch;
  //const condition6 = fechaRetiros === obtenerFechaActualFormateada() && codigoCliente !== 749 ;

  if (!condition2) {
    return sinFecha();
  } else if (!condition3) {
    return sinCliente();
  /* } else if (condition4) {
    return fechaMenor(); */
  } else if (!condition5) {
    return toastCabecera();
  } /* else if (condition6) {
    return fechaActual(); 
  } */ else {
    return true;
  }
};

export const LinearProgressOverlay = ({ isVisible, progress, completed }) => {
  return (
    <div>
      <Backdrop open={isVisible} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: "#fff" }}>
        <RedTransparentBackground progress={progress} completed={completed} />
      </Backdrop>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { listarVideos } from "../../../services/StorageUtilitiarioService"; // Importa la función desde el archivo de servicios
import { Button, Paper, Grid } from "@mui/material";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";

const DescargaVideos = () => {
  const [videos, setVideos] = useState([]);
  const [error, setError] = useState(null);

  // Cargar la lista de videos al montar el componente
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const videosList = await listarVideos();
        setVideos(videosList); // Guarda los videos en el estado
      } catch (err) {
        setError("Error al cargar la lista de videos");
      }
    };

    fetchVideos();
  }, []);

  const handleVideoClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div>
      <Paper style={{ padding: "20px" }}>
        {error ? (
          <p>{error}</p>
        ) : (
          <Grid container spacing={3}>
            {videos.map((video, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={2}
                key={index}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleVideoClick(video.url)}
                  sx={{
                    textTransform: "none",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    height: "100px",
                  }}
                >
                  <VideoCameraBackIcon sx={{ fontSize: 40, marginBottom: 1 }} />
                  {video.label}
                </Button>
              </Grid>
            ))}
          </Grid>
        )}
      </Paper>
    </div>
  );
};

export default DescargaVideos;


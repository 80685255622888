import axios from "axios";

/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

const apiClient = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

/* obtener comunas */
export const obtenerComunas = async () => {
  try {
    const response = await apiClient.get(`consolidado/obtenerComunas`);
    return response.data;
  } catch (error) {
    console.error("Error fetching obtenerComunas:", error);
    throw error;
  }
};

/* obtenemos clientes */
export const obtenerClientesControlSac = async () => {
  try {
    const response = await apiClient.get(`cedibles/obtenerClientes`);
    return response.data;
  } catch (error) {
    console.error("Error fetching obtenerClientesControlSac:", error);
    throw error;
  }
};

/* descargar archivo */
export const iniciarDescargaUnica = async (data) => {
  const response = await apiClient.post(`consultaKpis/DescargarPeriodo`, data);
  return response.data;
};

/* verificamos estado de la descarga */
export const verificarEstadoDescarga = async (downloadId) => {
  const response = await apiClient.get(`consultaKpis/estadoDescarga/${downloadId}`);
  return response.data;
};

/* Servicio para manejar la descarga de archivos */
export const descargarArchivo = (url) => {
  try {
    return url;
  } catch (error) {
    console.error("Error al generar la URL del archivo:", error);
    throw error;
  }
};

/* Para el dashboard */
export const obtenerEstadisticas = async (data) => {
  const response = await apiClient.post(`consultaKpis/estadisticas`, data);
  return response.data;
};

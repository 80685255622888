import React, { useState } from "react";
import DescargaVideos from "../../components/AdministracionComponentes/componentesDescarga/DescargaVideos";
import DescargaInformatica from "../../components/AdministracionComponentes/componentesDescarga/DescargaInformatica";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const DescargaDocumentacion = () => {
  const userInfo = useSelector((state) => state.user?.userInfo);
  const [selectedModule, setSelectedModule] = useState("");

  const renderModule = () => {
    switch (selectedModule) {
      case "DescargaInformatica":
        return <DescargaInformatica />;
      case "DescargaVideos":
        return <DescargaVideos />;
      default:
        return (
          <Typography variant="h6" align="center" sx={{ mt: 2 }}>
            Seleccione un módulo para mostrar
          </Typography>
        );
    }
  };

  return (
    <Box p={2}>
      <ButtonGroup
        variant="outlined"
        color="primary"
        aria-label="outlined primary button group"
        sx={{ mb: 2 }}
      >
        {userInfo?.tipo_usuario === "ADMIN" && (
          <Button
            onClick={() => setSelectedModule("DescargaInformatica")}
            sx={{
              backgroundColor: selectedModule === "DescargaInformatica" ? "#041562" : undefined,
              color: selectedModule === "DescargaInformatica" ? "#fff" : undefined,
              "&:hover": {
                backgroundColor: selectedModule === "DescargaInformatica" ? "#DA251C" : undefined,
              },
            }}
          >
            Documentos Informática
          </Button>
        )}
        <Button
          onClick={() => setSelectedModule("DescargaVideos")}
          sx={{
            backgroundColor: selectedModule === "DescargaVideos" ? "#041562" : undefined,
            color: selectedModule === "DescargaVideos" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "DescargaVideos" ? "#DA251C" : undefined,
            },
          }}
        >
          Videos
        </Button>
      </ButtonGroup>
      <Box>{renderModule()}</Box>
    </Box>
  );
};

export default DescargaDocumentacion;

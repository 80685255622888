import React from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { MenuItem, MenuList } from "@mui/material";
import { useSelector } from "react-redux";
function MobileMenu({ tipoUsuario, handleMobileMenuClose, navigate, openSubmenu, handleToggleSubmenu, handleLogout }) {
  const userInfo = useSelector((state) => state.user.userInfo);
  const menuRules = {
    ADMIN: {
      Inicio: "/",
      Consultas: {
        "Consulta individual": "/ConsultaIndividual",
        "Consulta masiva": "/ConsultaMasivo",
        "Consulta masiva nueva": "/ConsultaPage",
      },
      Despacho: {
        "Gestion de eventos": "/Eventos",
        "Correccion de orden": "/correccionOrden",
        "Carga de retiros": "/RetirosSac",
        "Carga de cedibles": "/cedibles",
        "Control cedibles": "/controlCedibles",
        "Rendicion de cedibles": "/RendicionCedibles",
        Listados: "/Listados",
        "Carga Retiros Santiago APP": "/RetirosAppStgo",
        "Carga Retiros Inter_Region APP": "/RetirosAppInter",
        "Retiros Santiago": "/RetirosSantiago",
        "Retiros Inter-Region": "/RetirosInterRegion",
        "Manifiesto Ruta Agentes": "/ManifiestoRutaAgentes",
      },
      Facturacion: {
        "Gestion de ordenes": "/ordenes",
        "Portal de movimientos": "/portalMovimientos",
        "Consulta Novofarma": "/DescargaNovofarmaRango",
      },
      "Operaciones Manifiestos por OD": {
         "Manifiesto Salida Inhouse ": "/ManifiestosSalidaInhouse",
          "Manifiesto Ingreso Bodega": "/ManifiestoIngresoBodega",
          "Manifiesto Salida Bodega": "/ManifiestoSalida",
          "Manifiesto Entrada Agente": "/ManifiestoEntradaAgente",
          "Manifiestos Rutas": "/ListadosManifiestos",
          "Manifiesto Retorno Agencias": "/ManifiestosRetorno",
          "Manifiesto Entrada Santiago": "/ManifiestoEntradaSantiago",
          "Manifiesto Retorno Clientes": "/ManifiestoRetornoCliente",
         
        },
          "Operaciones Manifiestos NOVO": {
          "Manifiesto Salida Inhouse NOVO ": "/ManifiestosSalidaInhouseRef",
          "Manifiesto Ingreso Bodega NOVO": "/ManifiestoIngresoBodegaRef",
          "Manifiesto Salida Bodega NOVO": "/ManifiestoSalidaRef",
          "Manifiesto Entrada Agente NOVO": "/ManifiestoEntradaAgenteRef",
          "Manifiestos Rutas NOVO": "/ManifiestosRutaRef",
          "Manifiesto Retorno Agencias NOVO": "/ManifiestosRetornoRef",
          "Manifiesto Entrada Santiago NOVO": "/ManifiestoEntradaSantiagoRef",
          "Manifiesto Retorno Clientes NOVO": "/ManifiestoRetornoClienteRef",
        },
        "Varios Operaciones": {
          "Editor de cubicos": "/CubicosOperaciones",
          "Edicion Medidas Novo": "/GestionMedidas",
          "Consulta Novo": "/ConsultaCodigoBarra",
          "Pendientes Manifiesto": "/ListadoPendientes",
          "Gestor de correos": "/ListaCorreos",
          "Descarga de manifiesto": "/DescargaManifiestos",
          "Gestor Manifiesto Salida": "/GestorManifiestoSalida",
          "Informe Diario": "/InformeCargaDiaria",
          "Consulta Informe Diario": "/ConsultaInformeCargaDiaria",
        },
        Administracion: {
        "Administrar usuarios": "/PortalUsuarios",
        "Administrar choferes": "/PortalChoferes",
        "Administrar clientes": "/PortalClientes",
        "Administrar vehiculos": "/PortalVehiculos",
        "Asignar clientes": "/AsignacionClientesEjecutivas",
        ControlSupervisora: "/ControlSupervisora",
        "Crear retiros prueba": "/CrearRetirosPrueba",
        "Descarga Documentacion": "/DescargaDocumentacion",
      },
      GRAFICOS: {
        Inicio: "/",
        ReportabilidadGeneral: "/ReportabilidadGeneral",
        "Reportabilidad ejecutiva": "/ReportabilidadEjecutiva",
      },
    },
    SAC: {
      Inicio: "/",
      consultas: {
        "Consulta individual": "/ConsultaIndividual",
        "Consulta masiva": "/ConsultaMasivo",
        "Consulta masiva nueva": "/ConsultaPage",
      },
      Despacho: {
        "Gestion de eventos": "/Eventos",
        "Correccion de orden": "/correccionOrden",
        "Carga de retiros": "/RetirosSac",
        "Carga de cedibles": "/cedibles",
        "Control de cedibles": "/controlCedibles",
        "Rendicion de cedibles": "/RendicionCedibles",
        Listados: "/Listados",
        "Carga Retiros Santiago APP": "/RetirosAppStgo",
        "Carga Retiros Inter_Region APP": "/RetirosAppInter",
        "Retiros Santiago": "/RetirosSantiago",
        "Retiros Inter-Region": "/RetirosInterRegion",
        "Manifiesto Ruta Agentes": "/ManifiestoRutaAgentes",
      },
      Facturacion: {
        Ordenes: "/ordenes",
        "Portal de movimientos": "/portalMovimientos",
      },
      Operaciones: {
        "Descarga de manifiesto": "/DescargaManifiestos",
        "Consulta Informe Diario": "/ConsultaInformeCargaDiaria",
      },
      Administracion: {
        "Descarga Documentacion": "/DescargaDocumentacion",
      },
    },
    OPERACIONES: {
      Inicio: "/",
      Consultas: {
        "Consulta individual": "/ConsultaIndividual",
      },
      "Operaciones Manifiestos por OD": {
         "Manifiesto Salida Inhouse ": "/ManifiestosSalidaInhouse",
          "Manifiesto Ingreso Bodega": "/ManifiestoIngresoBodega",
          "Manifiesto Salida Bodega": "/ManifiestoSalida",
          "Manifiesto Entrada Agente": "/ManifiestoEntradaAgente",
          "Manifiestos Rutas": "/ListadosManifiestos",
          "Manifiesto Retorno Agencias": "/ManifiestosRetorno",
          "Manifiesto Entrada Santiago": "/ManifiestoEntradaSantiago",
          "Manifiesto Retorno Clientes": "/ManifiestoRetornoCliente",
         
        },
          "Operaciones Manifiestos NOVO": {
          "Manifiesto Salida Inhouse NOVO ": "/ManifiestosSalidaInhouseRef",
          "Manifiesto Ingreso Bodega NOVO": "/ManifiestoIngresoBodegaRef",
          "Manifiesto Salida Bodega NOVO": "/ManifiestoSalidaRef",
          "Manifiesto Entrada Agente NOVO": "/ManifiestoEntradaAgenteRef",
          "Manifiestos Rutas NOVO": "/ManifiestosRutaRef",
          "Manifiesto Retorno Agencias NOVO": "/ManifiestosRetornoRef",
          "Manifiesto Entrada Santiago NOVO": "/ManifiestoEntradaSantiagoRef",
          "Manifiesto Retorno Clientes NOVO": "/ManifiestoRetornoClienteRef",
        },
        "Varios Operaciones": {
          "Consulta Novo": "/ConsultaCodigoBarra",
          "Descarga de manifiesto": "/DescargaManifiestos",
        },
        Administracion: {
          "Descarga Documentacion": "/DescargaDocumentacion",
        },
    },
    SUPERVISOR: {
      Inicio: "/",
      Consultas: {
        "Consulta individual": "/ConsultaIndividual",
        "Consulta masiva": "/ConsultaMasivo",
      },
      "Operaciones Manifiestos por OD": {
         "Manifiesto Salida Inhouse ": "/ManifiestosSalidaInhouse",
          "Manifiesto Ingreso Bodega": "/ManifiestoIngresoBodega",
          "Manifiesto Salida Bodega": "/ManifiestoSalida",
          "Manifiesto Entrada Agente": "/ManifiestoEntradaAgente",
          "Manifiestos Rutas": "/ListadosManifiestos",
          "Manifiesto Retorno Agencias": "/ManifiestosRetorno",
          "Manifiesto Entrada Santiago": "/ManifiestoEntradaSantiago",
          "Manifiesto Retorno Clientes": "/ManifiestoRetornoCliente",
         
        },
          "Operaciones Manifiestos NOVO": {
          "Manifiesto Salida Inhouse NOVO ": "/ManifiestosSalidaInhouseRef",
          "Manifiesto Ingreso Bodega NOVO": "/ManifiestoIngresoBodegaRef",
          "Manifiesto Salida Bodega NOVO": "/ManifiestoSalidaRef",
          "Manifiesto Entrada Agente NOVO": "/ManifiestoEntradaAgenteRef",
          "Manifiestos Rutas NOVO": "/ManifiestosRutaRef",
          "Manifiesto Retorno Agencias NOVO": "/ManifiestosRetornoRef",
          "Manifiesto Entrada Santiago NOVO": "/ManifiestoEntradaSantiagoRef",
          "Manifiesto Retorno Clientes NOVO": "/ManifiestoRetornoClienteRef",
        },
        "Varios Operaciones": {
          "Editor de cubicos": "/CubicosOperaciones",
          "Edicion Medidas Novo": "/GestionMedidas",
          "Consulta Novo": "/ConsultaCodigoBarra",
          "Pendientes Manifiesto": "/ListadoPendientes",
          "Gestor de correos": "/ListaCorreos",
          "Descarga de manifiesto": "/DescargaManifiestos",
          "Gestor Manifiesto Salida": "/GestorManifiestoSalida",
          "Informe Diario": "/InformeCargaDiaria",
          "Consulta Informe Diario": "/ConsultaInformeCargaDiaria",
        },
        Administracion: {
          "Descarga Documentacion": "/DescargaDocumentacion",
        },
    },
    SUPERVISORA: {
      Inicio: "/",
      consultas: {
        "Consulta individual": "/ConsultaIndividual",
        "Consulta masiva": "/ConsultaMasivo",
        "Consulta masiva nueva": "/ConsultaPage",
      },
      Despacho: {
        "Gestion de eventos": "/Eventos",
        "Correccion de orden": "/correccionOrden",
        "Carga de retiros": "/RetirosSac",
        "Carga de cedibles": "/cedibles",
        "Control de cedibles": "/controlCedibles",
        "Rendicion de cedibles": "/RendicionCedibles",
        Listados: "/Listados",
        "Carga Retiros Santiago APP": "/RetirosAppStgo",
        "Carga Retiros Inter_Region APP": "/RetirosAppInter",
        "Retiros Santiago": "/RetirosSantiago",
        "Retiros Inter-Region": "/RetirosInterRegion",
        "Manifiesto Ruta Agentes": "/ManifiestoRutaAgentes",
      },
      Facturacion: {
        Ordenes: "/ordenes",
        "Portal de movimientos": "/portalMovimientos",
      },
      Operaciones: {
        "Descarga de manifiesto": "/DescargaManifiestos",
        "Consulta Informe Diario": "/ConsultaInformeCargaDiaria",
      },
      Administracion: {
        "Asignar clientes": "/AsignacionClientesEjecutivas",
        ControlSupervisora: "/ControlSupervisora",
        PortalChoferes: "/PortalChoferes",
        "Descarga Documentacion": "/DescargaDocumentacion",
      },
    },
    CLIENTE: {
      Inicio: "/",
      Consultas: {
        ...(userInfo.cod_cliente === "632"
          ? {
              "Consulta por Código de Barra": "/ConsultaIndividualClientesCodigoBarra",
            }
          : {
              "Consulta Individual cliente": "/ConsultaIndividualClientes",
            }),
        "Consulta masiva cliente": "ConsultaMasivaCliente",
      },
      Clientes: {
        "Carga de retiros Santiago": "/RetirosClientesStgo",
        "Carga de retiros Inter-Region": "/RetirosClientesInter",
        "Control de cedibles": "/CediblesClientePage",
        "Control de cedible": "/ControlCediblesNovo",
        "Manifiesto salida inhouse": "/ManifiestosSalidaInhouse",
      },
    },
    GERENCIA: {
      Inicio: "/",
    },
    AGENTE: {
      Inicio: "/",
      Consultas: {
        "Consulta individual": "/ConsultaIndividualAgentes",
        "Consulta masiva": "/ConsultaMasivoAgentes",
        "Consulta masiva nueva": "/ConsultaMasivaAgentes",
      },
      "Manifiestos por OD": {
        "Manifiesto Entrada Agente": "ManifiestoEntradaAgente",
        "Manifiesto Retorno": "/ManifiestosRetorno",
        "Manifiestos Rutas": "/ListadosManifiestos",
      },
      "Manifiestos por Cliente": {
        "Manifiesto Entrada Agente": "ManifiestoEntradaAgenteRef",
        "Manifiesto Retorno Novo": "/ManifiestosRetornoRef",
        "Manifiestos Ruta Novo": "/ManifiestoRutaRef",
      },
      Varios: {
        "Descarga de manifiesto": "/DescargaManifiestos",
      },
      Administracion: {
        "Descarga Documentacion": "/DescargaDocumentacion",
      },
    },
    AGENTE_HUB: {
      Inicio: "/",
      "Manifiestos por OD": {
        "Manifiesto Entrada Agente": "ManifiestoEntradaAgente",
        "Manifiesto Retorno": "/ManifiestosRetorno",
        "Manifiestos Rutas": "/ListadosManifiestos",
      },
      "Manifiestos por Cliente": {
        "Manifiesto Entrada Agente": "ManifiestoEntradaAgenteRef",
        "Manifiesto Retorno Novo": "/ManifiestosRetornoRef",
        "Manifiestos Ruta Novo": "/ManifiestoRutaRef",
      },
      Varios: {
        "Descarga de manifiesto": "/DescargaManifiestos",
      },
      Administracion: {
        "Descarga Documentacion": "/DescargaDocumentacion",
      },
    },
    AGENTE_ADMIN: {
      Inicio: "/",
      Consultas: {
        "Consulta individual": "/ConsultaIndividualAgentes",
        "Consulta masiva": "/ConsultaMasivoAgentes",
        "Consulta masiva nueva": "/ConsultaMasivaAgentes",
      },
      "Manifiestos por OD": {
        "Manifiesto Entrada Agente": "ManifiestoEntradaAgente",
        "Manifiesto Retorno": "/ManifiestosRetorno",
        "Manifiestos Rutas": "/ListadosManifiestos",
      },
      "Manifiestos por Cliente": {
        "Manifiesto Entrada Agente": "ManifiestoEntradaAgenteRef",
        "Manifiesto Retorno Novo": "/ManifiestosRetornoRef",
        "Manifiestos Ruta Novo": "/ManifiestoRutaRef",
      },
      Varios: {
        "Descarga de manifiesto": "/DescargaManifiestos",
      },
      Administracion: {
        "Descarga Documentacion": "/DescargaDocumentacion",
      },
    },

    FACTURACION: {
      Inicio: "/",
      Consultas: {
        "Consulta individual": "/ConsultaIndividual",
        "Consulta masiva": "/ConsultaMasivo",
        "Consulta masiva nueva": "/ConsultaPage",
      },
      Facturacion: {
        Listados: "/Listados",
        "Correccion de ordenes": "/correccionOrden",
        "Gestion de ordenes": "/ordenes",
        "Portal de movimientos": "/portalMovimientos",
      },
    },
    GRAFICOS: {
      Inicio: "/",
      ReportabilidadGeneral: "/ReportabilidadGeneral",
      "Reportabilidad ejecutiva": "/ReportabilidadEjecutiva",
    },
    JEFATURA: {
      Inicio: "/",
      Consultas: {
        "Consulta individual": "/ConsultaIndividual",
        "Consulta masiva": "/ConsultaMasivo",
        "Consulta masiva nueva": "/ConsultaPage",
      },
      Despacho: {
        Listados: "/Listados",
      },
      Operaciones: {
        Varios: "/VariosOperaciones",
        "Descarga manifiestos": "/DescargaManifiestos",
      },
      Graficos: {
        "Reportabilidad ejecutiva": "/ReportabilidadEjecutiva",
        "Reportabilidad general": "/ReportabilidadGeneral",
      },
    },
    CONTROL_CALIDAD: {
      inicio: "/",
      Consultas: {
        "Consulta individual": "/ConsultaIndividual",
        "Consulta masiva": "/ConsultaMasivo",
        "Consulta masiva nueva": "/ConsultaPage",
      },
      Despacho: {
        Listados: "/Listados",
      },
      "Control Calidad": {
        "Datos clientes": "/ClientesControlCalidad",
      },
    },
  };

  const renderMenuItems = (items, parentKey = "") => {
    return Object.entries(items)
      .map(([key, value]) => {
        const isSubmenu = typeof value === "object";
        const menuItemKey = parentKey ? `${parentKey}-${key}` : key;

        if (isSubmenu) {
          return [
            <MenuItem key={menuItemKey} onClick={() => handleToggleSubmenu(menuItemKey)}>
              {key} {openSubmenu === menuItemKey ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>,
            openSubmenu === menuItemKey && (
              <MenuList key={`submenu-${menuItemKey}`}>{renderMenuItems(value, menuItemKey)}</MenuList>
            ),
          ];
        } else {
          return (
            <MenuItem
              key={menuItemKey}
              onClick={() => {
                navigate(value);
                handleMobileMenuClose();
              }}
            >
              {key}
            </MenuItem>
          );
        }
      })
      .flat();
  };

  return (
    <MenuList>
      {renderMenuItems(menuRules[tipoUsuario])}
      <MenuItem onClick={handleLogout} sx={{ color: "#DA251C" }}>
        Cerrar sesión
      </MenuItem>
    </MenuList>
  );
}

export default MobileMenu;

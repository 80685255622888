import React from "react";
import { Box } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";

const TableKpi = ({ columns, rows, loading }) => {
  return (
    <Box sx={{ height: 580, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSizeOptions={[5, 10, 20, 50]}
        loading={loading}
        autoPageSize={false}
        checkboxSelection={false}
        disableRowSelectionOnClick
        getRowId={(row) => row.id || row.nombreCliente}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}
        sx={{
          ".MuiDataGrid-virtualScroller": {
            overflowX: "auto",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f5f5f5",
            fontWeight: "bold",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "#f0f0f0",
          },
        }}
      />
    </Box>
  );
};

export default TableKpi;

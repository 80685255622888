import { toast } from "react-toastify";
import obtenerTrazabilidadOD from "../../../services/ConsultaPublicaService"
import {
  TextField,
  Button,
  Box,
} from '@mui/material';
function BuscarOD({od, inputRef, setData, setReqError}) {
    /* Checkear si el valor del input es valido (Solo enteros) */
    const handleChange = (e, valueSetter) => {
        const inputValue = e.target.value;
        //Regex exp only accept numbers integeres
        if ((/^-?\d*$/.test(inputValue))) {
            valueSetter.current = inputValue;
        } else {
            e.target.value = valueSetter.current;
        }
    };

    /* Fetchear data segun OD en inputRef */
    const handleSubmit = (e, setData, setError, value) => {
        e.preventDefault();
        if (typeof (value.current) === "string" && value.current !== "") {
            obtenerTrazabilidadOD(setData, setError, value.current);
            value.current = "";
        } else toast.warning("Ya está visualizando la información solicitada.")
    }
    return (
        <div style={{ px: 3, justifyContent: "center", alignItems: "center", display: "grid", gridTemplateRows: "auto 1fr", }}>
            <Box >
                {od === null && (
                    <form
                        onSubmit={(e) => handleSubmit(e, setData, setReqError, inputRef)}
                        style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                        }}
                    >
                        <TextField
                            variant="outlined"
                            label="Search"
                            onChange={(e) => handleChange(e, inputRef)}
                            inputRef={inputRef}
                            fullWidth
                            sx={{
                                maxWidth: "500px",
                                "& .MuiOutlinedInput-root": {
                                    height: "56px",
                                    borderRadius: "10px",
                                },
                            }}
                            placeholder="Ingrese OD"
                        />
                        <Button
                            variant="contained"
                            type="submit"
                            id="search-btn"
                            sx={{
                                height: "56px",
                                minWidth: "120px",
                                borderRadius: "10px",
                                backgroundColor: "#041562",
                                color: "#fff",
                            }}
                        >
                            Buscar OD
                        </Button>
                    </form>
                )}

            </Box>
        </div>
    )
}

export default BuscarOD;
export const comunas = [
  // Arica y Parinacota
  { nombre: "Arica", codCNE: "15101" },
  { nombre: "Camarones", codCNE: "15102" },
  { nombre: "Putre", codCNE: "15201" },
  { nombre: "General Lagos", codCNE: "15202" },

  // Tarapacá
  { nombre: "Iquique", codCNE: "01101" },
  { nombre: "Alto Hospicio", codCNE: "01107" },
  { nombre: "Pozo Almonte", codCNE: "01401" },
  { nombre: "Camiña", codCNE: "01402" },
  { nombre: "Colchane", codCNE: "01403" },
  { nombre: "Huara", codCNE: "01404" },
  { nombre: "Pica", codCNE: "01405" },

  // Antofagasta
  { nombre: "Antofagasta", codCNE: "02101" },
  { nombre: "Mejillones", codCNE: "02102" },
  { nombre: "Sierra Gorda", codCNE: "02103" },
  { nombre: "Taltal", codCNE: "02104" },
  { nombre: "Calama", codCNE: "02201" },
  { nombre: "Ollagüe", codCNE: "02202" },
  { nombre: "San Pedro de Atacama", codCNE: "02203" },
  { nombre: "Tocopilla", codCNE: "02301" },
  { nombre: "María Elena", codCNE: "02302" },

  // Atacama
  { nombre: "Copiapó", codCNE: "03101" },
  { nombre: "Caldera", codCNE: "03102" },
  { nombre: "Tierra Amarilla", codCNE: "03103" },
  { nombre: "Chañaral", codCNE: "03201" },
  { nombre: "Diego de Almagro", codCNE: "03202" },
  { nombre: "Vallenar", codCNE: "03301" },
  { nombre: "Alto del Carmen", codCNE: "03302" },
  { nombre: "Freirina", codCNE: "03303" },
  { nombre: "Huasco", codCNE: "03304" },

  // Coquimbo
  { nombre: "La Serena", codCNE: "04101" },
  { nombre: "Coquimbo", codCNE: "04102" },
  { nombre: "Andacollo", codCNE: "04103" },
  { nombre: "La Higuera", codCNE: "04104" },
  { nombre: "Paihuano", codCNE: "04101" },
  { nombre: "Vicuña", codCNE: "04106" },
  { nombre: "Illapel", codCNE: "04201" },
  { nombre: "Canela", codCNE: "04202" },
  { nombre: "Los Vilos", codCNE: "04203" },
  { nombre: "Salamanca", codCNE: "04204" },
  { nombre: "Ovalle", codCNE: "04301" },
  { nombre: "Combarbalá", codCNE: "04302" },
  { nombre: "Monte Patria", codCNE: "04303" },
  { nombre: "Punitaqui", codCNE: "04304" },
  { nombre: "Río Hurtado", codCNE: "04305" },

  // Valparaíso
  { nombre: "Valparaíso", codCNE: "05101" },
  { nombre: "Viña del Mar", codCNE: "05109" },
  { nombre: "Concón", codCNE: "05103" },
  { nombre: "Quintero", codCNE: "05107" },
  { nombre: "Puchuncaví", codCNE: "05105" },
  { nombre: "Casablanca", codCNE: "05102" },
  { nombre: "Juan Fernández", codCNE: "05104" },
  { nombre: "San Antonio", codCNE: "05601" },
  { nombre: "Cartagena", codCNE: "05603" },
  { nombre: "El Tabo", codCNE: "05605" },
  { nombre: "El Quisco", codCNE: "05604" },
  { nombre: "Algarrobo", codCNE: "05602" },
  { nombre: "Quillota", codCNE: "05501" },
  { nombre: "La Calera", codCNE: "05502" },
  { nombre: "Hijuelas", codCNE: "05503" },
  { nombre: "La Cruz", codCNE: "05504" },
  { nombre: "Nogales", codCNE: "05506" },
  { nombre: "San Felipe", codCNE: "05701" },
  { nombre: "Llaillay", codCNE: "05703" },
  { nombre: "Putaendo", codCNE: "05705" },
  { nombre: "Santa María", codCNE: "05706" },
  { nombre: "Los Andes", codCNE: "05301" },
  { nombre: "Calle Larga", codCNE: "05302" },
  { nombre: "Rinconada", codCNE: "05303" },
  { nombre: "San Esteban", codCNE: "05304" },
  { nombre: "Petorca", codCNE: "05404" },
  { nombre: "La Ligua", codCNE: "05401" },
  { nombre: "Cabildo", codCNE: "05402" },
  { nombre: "Papudo", codCNE: "05403" },
  { nombre: "Zapallar", codCNE: "05405" },

  // Metropolitana de Santiago
  { nombre: "Santiago", codCNE: "13101" },
  { nombre: "Cerrillos", codCNE: "13102" },
  { nombre: "Cerro Navia", codCNE: "13103" },
  { nombre: "Conchalí", codCNE: "13104" },
  { nombre: "El Bosque", codCNE: "13105" },
  { nombre: "Estación Central", codCNE: "13106" },
  { nombre: "Huechuraba", codCNE: "13107" },
  { nombre: "Independencia", codCNE: "13108" },
  { nombre: "La Cisterna", codCNE: "13109" },
  { nombre: "La Florida", codCNE: "13110" },
  { nombre: "La Granja", codCNE: "13111" },
  { nombre: "La Pintana", codCNE: "13112" },
  { nombre: "La Reina", codCNE: "13113" },
  { nombre: "Las Condes", codCNE: "13114" },
  { nombre: "Lo Barnechea", codCNE: "13115" },
  { nombre: "Lo Espejo", codCNE: "13116" },
  { nombre: "Lo Prado", codCNE: "13117" },
  { nombre: "Macul", codCNE: "13118" },
  { nombre: "Maipú", codCNE: "13119" },
  { nombre: "Ñuñoa", codCNE: "13120" },
  { nombre: "Pedro Aguirre Cerda", codCNE: "13121" },
  { nombre: "Peñalolén", codCNE: "13122" },
  { nombre: "Providencia", codCNE: "13123" },
  { nombre: "Pudahuel", codCNE: "13124" },
  { nombre: "Quilicura", codCNE: "13125" },
  { nombre: "Quinta Normal", codCNE: "13126" },
  { nombre: "Recoleta", codCNE: "13127" },
  { nombre: "Renca", codCNE: "13128" },
  { nombre: "San Joaquín", codCNE: "13129" },
  { nombre: "San Miguel", codCNE: "13130" },
  { nombre: "San Ramón", codCNE: "13131" },
  { nombre: "Vitacura", codCNE: "13132" },
  { nombre: "Puente Alto", codCNE: "13201" },
  { nombre: "Pirque", codCNE: "13202" },
  { nombre: "San José de Maipo", codCNE: "13203" },
  { nombre: "Colina", codCNE: "13301" },
  { nombre: "Lampa", codCNE: "13302" },
  { nombre: "Tiltil", codCNE: "13303" },
  { nombre: "Buin", codCNE: "13402" },
  { nombre: "Calera de Tango", codCNE: "13403" },
  { nombre: "Paine", codCNE: "13404" },
  { nombre: "Melipilla", codCNE: "13501" },
  { nombre: "Alhué", codCNE: "13502" },
  { nombre: "Curacaví", codCNE: "13503" },
  { nombre: "María Pinto", codCNE: "13504" },
  { nombre: "San Pedro", codCNE: "13505" },
  { nombre: "Talagante", codCNE: "13601" },
  { nombre: "El Monte", codCNE: "13602" },
  { nombre: "Isla de Maipo", codCNE: "13603" },
  { nombre: "Padre Hurtado", codCNE: "13604" },
  { nombre: "Peñaflor", codCNE: "13605" },

  // Del Libertador Gral. Bernardo O’Higgins
  { nombre: "Rancagua", codCNE: "06101" },
  { nombre: "Codegua", codCNE: "06102" },
  { nombre: "Coinco", codCNE: "06103" },
  { nombre: "Coltauco", codCNE: "06104" },
  { nombre: "Doñihue", codCNE: "06105" },
  { nombre: "Graneros", codCNE: "06106" },
  { nombre: "Las Cabras", codCNE: "06107" },
  { nombre: "Machalí", codCNE: "06108" },
  { nombre: "Malloa", codCNE: "06109" },
  { nombre: "Mostazal", codCNE: "06110" },
  { nombre: "Olivar", codCNE: "06111" },
  { nombre: "Peumo", codCNE: "06112" },
  { nombre: "Pichidegua", codCNE: "06113" },
  { nombre: "Quinta de Tilcoco", codCNE: "06114" },
  { nombre: "Rengo", codCNE: "06115" },
  { nombre: "Requínoa", codCNE: "06116" },
  { nombre: "San Vicente", codCNE: "06117" },
  { nombre: "Pichilemu", codCNE: "06201" },
  { nombre: "La Estrella", codCNE: "06202" },
  { nombre: "Litueche", codCNE: "06203" },
  { nombre: "Marchihue", codCNE: "06204" },
  { nombre: "Navidad", codCNE: "06205" },
  { nombre: "Paredones", codCNE: "06206" },
  { nombre: "San Fernando", codCNE: "06301" },
  { nombre: "Chépica", codCNE: "06302" },
  { nombre: "Chimbarongo", codCNE: "06303" },
  { nombre: "Lolol", codCNE: "06304" },
  { nombre: "Nancagua", codCNE: "06305" },
  { nombre: "Palmilla", codCNE: "06306" },
  { nombre: "Peralillo", codCNE: "06307" },
  { nombre: "Placilla", codCNE: "06308" },
  { nombre: "Pumanque", codCNE: "06309" },
  { nombre: "Santa Cruz", codCNE: "06310" },

  // Del Maule
  { nombre: "Talca", codCNE: "07101" },
  { nombre: "Constitución", codCNE: "07102" },
  { nombre: "Curepto", codCNE: "07103" },
  { nombre: "Empedrado", codCNE: "07104" },
  { nombre: "Maule", codCNE: "07105" },
  { nombre: "Pelarco", codCNE: "07106" },
  { nombre: "Pencahue", codCNE: "07107" },
  { nombre: "Río Claro", codCNE: "07108" },
  { nombre: "San Clemente", codCNE: "07109" },
  { nombre: "San Rafael", codCNE: "07110" },
  { nombre: "Cauquenes", codCNE: "07201" },
  { nombre: "Chanco", codCNE: "07202" },
  { nombre: "Pelluhue", codCNE: "07203" },
  { nombre: "Curicó", codCNE: "07301" },
  { nombre: "Hualañé", codCNE: "07302" },
  { nombre: "Licantén", codCNE: "07303" },
  { nombre: "Molina", codCNE: "07304" },
  { nombre: "Rauco", codCNE: "07305" },
  { nombre: "Romeral", codCNE: "07306" },
  { nombre: "Sagrada Familia", codCNE: "07307" },
  { nombre: "Teno", codCNE: "07308" },
  { nombre: "Vichuquén", codCNE: "07309" },
  { nombre: "Linares", codCNE: "07401" },
  { nombre: "Colbún", codCNE: "07402" },
  { nombre: "Longaví", codCNE: "07403" },
  { nombre: "Parral", codCNE: "07404" },
  { nombre: "Retiro", codCNE: "07405" },
  { nombre: "San Javier", codCNE: "07406" },
  { nombre: "Villa Alegre", codCNE: "07407" },
  { nombre: "Yerbas Buenas", codCNE: "07408" },

  // Ñuble
  { nombre: "Chillán", codCNE: "16101" },
  { nombre: "Bulnes", codCNE: "16102" },
  { nombre: "Cobquecura", codCNE: "16202" },
  { nombre: "Coelemu", codCNE: "16203" },
  { nombre: "Coihueco", codCNE: "16302" },
  { nombre: "El Carmen", codCNE: "16104" },
  { nombre: "Ninhue", codCNE: "16204" },
  { nombre: "Ñiquén", codCNE: "16303" },
  { nombre: "Pemuco", codCNE: "16105" },
  { nombre: "Pinto", codCNE: "16106" },
  { nombre: "Portezuelo", codCNE: "16205" },
  { nombre: "Quillón", codCNE: "16107" },
  { nombre: "Quirihue", codCNE: "16201" },
  { nombre: "Ránquil", codCNE: "16206" },
  { nombre: "San Carlos", codCNE: "16301" },
  { nombre: "San Fabián", codCNE: "16304" },
  { nombre: "San Ignacio", codCNE: "16108" },
  { nombre: "San Nicolás", codCNE: "16305" },
  { nombre: "Treguaco", codCNE: "16207" },
  { nombre: "Yungay", codCNE: "16109" },

  // Del Biobío
  { nombre: "Concepción", codCNE: "08101" },
  { nombre: "Coronel", codCNE: "08102" },
  { nombre: "Chiguayante", codCNE: "08103" },
  { nombre: "Florida", codCNE: "08104" },
  { nombre: "Hualqui", codCNE: "08105" },
  { nombre: "Lota", codCNE: "08106" },
  { nombre: "Penco", codCNE: "08107" },
  { nombre: "San Pedro de la Paz", codCNE: "08108" },
  { nombre: "Santa Juana", codCNE: "08109" },
  { nombre: "Talcahuano", codCNE: "08110" },
  { nombre: "Tomé", codCNE: "08111" },
  { nombre: "Hualpén", codCNE: "08112" },
  { nombre: "Los Ángeles", codCNE: "08301" },
  { nombre: "Antuco", codCNE: "08302" },
  { nombre: "Cabrero", codCNE: "08303" },
  { nombre: "Laja", codCNE: "08304" },
  { nombre: "Mulchén", codCNE: "08305" },
  { nombre: "Nacimiento", codCNE: "08306" },
  { nombre: "Negrete", codCNE: "08307" },
  { nombre: "Quilaco", codCNE: "08308" },
  { nombre: "Quilleco", codCNE: "08309" },
  { nombre: "San Rosendo", codCNE: "08310" },
  { nombre: "Santa Bárbara", codCNE: "08311" },
  { nombre: "Tucapel", codCNE: "08312" },
  { nombre: "Yumbel", codCNE: "08313" },
  { nombre: "Alto Biobío", codCNE: "08314" },

  // De la Araucanía
  { nombre: "Temuco", codCNE: "09101" },
  { nombre: "Carahue", codCNE: "09102" },
  { nombre: "Cunco", codCNE: "09103" },
  { nombre: "Curarrehue", codCNE: "09104" },
  { nombre: "Freire", codCNE: "09105" },
  { nombre: "Galvarino", codCNE: "09106" },
  { nombre: "Gorbea", codCNE: "09107" },
  { nombre: "Lautaro", codCNE: "09108" },
  { nombre: "Loncoche", codCNE: "09109" },
  { nombre: "Melipeuco", codCNE: "09110" },
  { nombre: "Nueva Imperial", codCNE: "09111" },
  { nombre: "Padre Las Casas", codCNE: "09112" },
  { nombre: "Perquenco", codCNE: "09113" },
  { nombre: "Pitrufquén", codCNE: "09114" },
  { nombre: "Pucón", codCNE: "09115" },
  { nombre: "Saavedra", codCNE: "09116" },
  { nombre: "Teodoro Schmidt", codCNE: "09117" },
  { nombre: "Toltén", codCNE: "09118" },
  { nombre: "Vilcún", codCNE: "09119" },
  { nombre: "Villarrica", codCNE: "09120" },
  { nombre: "Cholchol", codCNE: "09121" },

  // De los Ríos
  { nombre: "Valdivia", codCNE: "14101" },
  { nombre: "Corral", codCNE: "14102" },
  { nombre: "Lanco", codCNE: "14103" },
  { nombre: "Los Lagos", codCNE: "14104" },
  { nombre: "Máfil", codCNE: "14105" },
  { nombre: "Mariquina", codCNE: "14106" },
  { nombre: "Paillaco", codCNE: "14107" },
  { nombre: "Panguipulli", codCNE: "14108" },
  { nombre: "La Unión", codCNE: "14201" },
  { nombre: "Futrono", codCNE: "14202" },
  { nombre: "Lago Ranco", codCNE: "14203" },
  { nombre: "Río Bueno", codCNE: "14204" },

  // De los Lagos
  { nombre: "Puerto Montt", codCNE: "10101" },
  { nombre: "Calbuco", codCNE: "10102" },
  { nombre: "Cochamó", codCNE: "10103" },
  { nombre: "Fresia", codCNE: "10104" },
  { nombre: "Frutillar", codCNE: "10105" },
  { nombre: "Los Muermos", codCNE: "10106" },
  { nombre: "Llanquihue", codCNE: "10107" },
  { nombre: "Maullín", codCNE: "10108" },
  { nombre: "Puerto Varas", codCNE: "10109" },
  { nombre: "Castro", codCNE: "10201" },
  { nombre: "Ancud", codCNE: "10202" },
  { nombre: "Chonchi", codCNE: "10203" },
  { nombre: "Curaco de Vélez", codCNE: "10204" },
  { nombre: "Dalcahue", codCNE: "10205" },
  { nombre: "Puqueldón", codCNE: "10206" },
  { nombre: "Queilén", codCNE: "10207" },
  { nombre: "Quellón", codCNE: "10208" },
  { nombre: "Quemchi", codCNE: "10209" },
  { nombre: "Quinchao", codCNE: "10210" },
  { nombre: "Osorno", codCNE: "10301" },
  { nombre: "Puerto Octay", codCNE: "10302" },
  { nombre: "Purranque", codCNE: "10303" },
  { nombre: "Puyehue", codCNE: "10304" },
  { nombre: "Río Negro", codCNE: "10305" },
  { nombre: "San Juan de la Costa", codCNE: "10306" },
  { nombre: "San Pablo", codCNE: "10307" },

  // Aysén del Gral. Carlos Ibáñez del Campo
  { nombre: "Coihaique", codCNE: "11101" },
  { nombre: "Lago Verde", codCNE: "11102" },
  { nombre: "Aysén", codCNE: "11201" },
  { nombre: "Cisnes", codCNE: "11202" },
  { nombre: "Guaitecas", codCNE: "11203" },
  { nombre: "Cochrane", codCNE: "11301" },
  { nombre: "O'Higgins", codCNE: "11303" },
  { nombre: "Tortel", codCNE: "11303" },
  { nombre: "Chile Chico", codCNE: "11401" },
  { nombre: "Río Ibáñez", codCNE: "11402" },

  // Magallanes y de la Antártica Chilena
  { nombre: "Punta Arenas", codCNE: "12101" },
  { nombre: "Laguna Blanca", codCNE: "12102" },
  { nombre: "Río Verde", codCNE: "12103" },
  { nombre: "San Gregorio", codCNE: "12104" },
  { nombre: "Cabo de Hornos", codCNE: "12201" },
  { nombre: "Antártica", codCNE: "12202" },
  { nombre: "Porvenir", codCNE: "12301" },
  { nombre: "Primavera", codCNE: "12302" },
  { nombre: "Timaukel", codCNE: "12303" },
  { nombre: "Natales", codCNE: "12401" },
  { nombre: "Torres del Paine", codCNE: "12402" },
];

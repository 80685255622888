import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
  Box
} from "@mui/material";
import { guardarCabecerasUsuario, obtenerCabecerasPersonalizadas } from "../../../services/ReportesServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import defaultHeaders from "../../../utils/defaultHeaders";

const HeaderSelector = ({ userId, userHeaders, onUpdateHeaders }) => {
  const [selectedHeaders, setSelectedHeaders] = useState(userHeaders || defaultHeaders);

  useEffect(() => {
    setSelectedHeaders(userHeaders?.length > 0 ? userHeaders : defaultHeaders);
  }, [userHeaders]);

  const handleHeaderChange = (header) => {
    setSelectedHeaders((prev) =>
      prev.includes(header) ? prev.filter((h) => h !== header) : [...prev, header]
    );
  };

  const selectAllHeaders = () => {
    setSelectedHeaders(defaultHeaders);
  };

  const deselectAllHeaders = () => {
    setSelectedHeaders([]);
  };

  const savePreferences = async () => {
    try {
      await guardarCabecerasUsuario(userId, selectedHeaders);
      const updatedHeaders = await obtenerCabecerasPersonalizadas(userId);
      toast.success("Cabeceras guardadas con éxito");
      onUpdateHeaders(updatedHeaders);
    } catch (error) {
      console.error("Error al guardar cabeceras:", error);
      toast.error("Error al guardar cabeceras");
    }
  };

  return (
    <FormControl component="fieldset" variant="standard" style={{ width: '100%' }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(8, 1fr)',
          gap: 1,
          maxHeight: '150px',
          overflowY: 'auto',
          padding: '8px',
          border: '1px solid #ccc',
          borderRadius: '4px'
        }}
      >
        {defaultHeaders.map((header) => (
          <FormControlLabel
            key={header}
            control={
              <Checkbox
                checked={selectedHeaders.includes(header)}
                onChange={() => handleHeaderChange(header)}
                size="small"
              />
            }
            label={header}
            sx={{
              '& .MuiFormControlLabel-label': { 
                fontSize: '0.8rem'
              }
            }}
          />
        ))}
      </Box>

      <div style={{ marginTop: 20, display: 'flex', gap: '10px' }}>
        <Button onClick={selectAllHeaders} variant="outlined" color="primary">
          Seleccionar Todas
        </Button>
        <Button onClick={deselectAllHeaders} variant="outlined" color="primary">
          Deseleccionar Todas
        </Button>
        <Button onClick={savePreferences} variant="contained" color="primary">
          Guardar preferencias para excel
        </Button>
      </div>
    </FormControl>
  );
};

export default HeaderSelector;

import React, { memo, useState } from "react";
import PendientesEntradaSalida from "./../../components/OperacionesComponentes/componentesPendientes/PendientesEntradaSalida";
import PendientesEntradaSantiago from "./../../components/OperacionesComponentes/componentesPendientes/PendientesEntradaSantiago";
import PendientesTotalRetornoClientes from "../../components/OperacionesComponentes/componentesPendientes/PendientesTotalRetornoClientes";
import EstadoManifiestoRuta from "../../components/OperacionesComponentes/componentesPendientes/EstadoManifiestoRuta";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const ListadosManifiestos = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const tipo_usuario = userInfo ? userInfo.tipo_usuario : null;
  const [selectedModule, setSelectedModule] = useState("");

  const renderModule = () => {
    switch (selectedModule) {
      case "PendientesEntradaSalida":
        return <PendientesEntradaSalida />;
      case "PendientesEntradaSantiago":
        return <PendientesEntradaSantiago />;
      case "PendientesTotalRetornoClientes":
        return <PendientesTotalRetornoClientes />;
      case "EstadoManifiestoRuta":
        return <EstadoManifiestoRuta />;
      default:
        return <Typography variant="h6">Seleccione un módulo para mostrar</Typography>;
    }
  };

  // Determinar si deshabilitar opciones que no son EstadoManifiestoRuta
  const isRestrictedUser =
    tipo_usuario === "AGENTE" || tipo_usuario === "AGENTE_HUB" || tipo_usuario === "AGENTE_ADMIN";

  return (
    <Box p={1}>
      <ButtonGroup variant="outlined" color="primary" aria-label="outlined primary button group">
        <Button
          onClick={() => setSelectedModule("PendientesEntradaSalida")}
          disabled={isRestrictedUser}
          sx={{
            backgroundColor: selectedModule === "PendientesEntradaSalida" ? "#041562" : undefined,
            color: selectedModule === "PendientesEntradaSalida" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "PendientesEntradaSalida" ? "#DA251C" : undefined,
            },
          }}
        >
          Pendientes Entrada/Salida
        </Button>

        <Button
          onClick={() => setSelectedModule("PendientesEntradaSantiago")}
          disabled={isRestrictedUser}
          sx={{
            backgroundColor: selectedModule === "PendientesEntradaSantiago" ? "#041562" : undefined,
            color: selectedModule === "PendientesEntradaSantiago" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "PendientesEntradaSantiago" ? "#DA251C" : undefined,
            },
          }}
        >
          Pendientes Entrada Santiago
        </Button>
        <Button
          onClick={() => setSelectedModule("PendientesTotalRetornoClientes")}
          disabled={isRestrictedUser}
          sx={{
            backgroundColor: selectedModule === "PendientesTotalRetornoClientes" ? "#041562" : undefined,
            color: selectedModule === "PendientesTotalRetornoClientes" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "PendientesTotalRetornoClientes" ? "#DA251C" : undefined,
            },
          }}
        >
          Pendientes Retorno Cliente
        </Button>
        <Button
          onClick={() => setSelectedModule("EstadoManifiestoRuta")}
          sx={{
            backgroundColor: selectedModule === "EstadoManifiestoRuta" ? "#041562" : undefined,
            color: selectedModule === "EstadoManifiestoRuta" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "EstadoManifiestoRuta" ? "#DA251C" : undefined,
            },
          }}
        >
          Estado Manifiesto Ruta
        </Button>
      </ButtonGroup>
      <Box mt={1}>{renderModule()}</Box>
    </Box>
  );
};

export default memo(ListadosManifiestos);

import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

export const guardarDetalleCarga = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}operaciones/insertDetalleCarga`,

      data,
    );
    return response.data;
  } catch (error) {
    console.error("Error al guardar el detalle de carga:", error);
    throw error;
  }
};

export const getManifiestoDetalleCarga = async (agenteAsignado) => {
  try {
    const response = await axios.get(`${BASE_API_URL}operaciones/getManifiestoDetalleCarga`, {
      params: { agenteAsignado },
    });

    if (response.data.data && response.data.data.length === 0) {
      
      return [];
    }

    return response.data;
  } catch (error) {
    
    return []; 
  }
};

export async function getInformeDiario(fecha) {
  try {
    const response = await axios.post(`${BASE_API_URL}operaciones/getInformeDiario`, {
      datos: { FECHA: fecha },
    });
    return response.data; 
  } catch (error) {
    console.error('Error al obtener el informe diario:', error);
    throw error; 
  }
}

export async function getEstadoManifiestoRutaxId(id_manifiesto) {
  try {
    const response = await axios.post(`${BASE_API_URL}operaciones/getEstadoManifiestoRutaxId`, {
      datos: { ID: id_manifiesto },
    });
    return response.data; 
  } catch (error) {
    console.error('Error al obtener el informe diario:', error);
    throw error; 
  }
}

export async function getEstadoManifiestoRutaChofer(cod_chofer, iata, fecha) {
  try {
    const response = await axios.post(`${BASE_API_URL}operaciones/getEstadoManifiestoRutaChofer`, {
      datos: { CHOFER: cod_chofer, IATA: iata, FECHA: fecha },
    });
    return response.data; 
  } catch (error) {
    console.error('Error al obtener el informe diario:', error);
    throw error; 
  }
}

export const confirmacionBultos = async (data) => {
  try {
    const response = await axios.put(
      `${BASE_API_URL}operaciones/updateBultosOrden`,

      data,
    );
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos PT desde API.js", error);
    throw error;
  }
};

export const SolicitudOrden = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}operaciones/SolicitudOrden`,

      data,
    );
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos desde API.js", error);
    throw error;
  }
};

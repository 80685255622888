import React, { useState, useCallback } from "react";
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from "@react-google-maps/api";
import ciudadesPrincipales from "../../utils/ciudades";

const containerStyle = {
  width: "100%",
  height: "600px",
};

const center = { lat: -35.6751, lng: -71.543 };

const MapaMatrizTransito = () => {
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBITfQPAHSXfc_Lw09kwU1TlwJVbJJwMtY", // Reemplaza con tu clave real
  });

  const handleClickCiudadPrincipal = useCallback(
    (city) => {
      if (selectedCity && selectedCity.name === city.name) {
        setSelectedCity(null);
      } else {
        setSelectedCity(city);
      }
    },
    [selectedCity],
  );

  const handleClickMarker = useCallback((marker) => {
    setSelectedMarker(marker);
  }, []);

  const handleCloseInfoWindow = useCallback(() => {
    setSelectedMarker(null);
  }, []);

  if (!isLoaded) return <div>Cargando mapa...</div>;

  return (
    <div style={{ position: "relative" }}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={5} // Un zoom 5 te mostrará mejor la forma de Chile
        options={{
          restriction: {
            latLngBounds: {
              north: -15.0, // un poco más al norte
              south: -60.0, // un poco más al sur
              west: -84.0, // más al oeste
              east: -62.0, // más al este
            },
            strictBounds: false,
          },
        }}
      >
        {/* Marcadores de ciudades principales */}
        {ciudadesPrincipales.map((ciudadPrincipal, index) => (
          <Marker
            key={`cp-${index}`}
            position={{ lat: ciudadPrincipal.lat, lng: ciudadPrincipal.lng }}
            icon={{
              url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png", // Ícono azul de Google
            }}
            onClick={() => {
              handleClickMarker({
                name: ciudadPrincipal.name,
                tiempo: ciudadPrincipal.tiempo,
                lat: ciudadPrincipal.lat,
                lng: ciudadPrincipal.lng,
                tipo: ciudadPrincipal.tipo || "CIUDAD PRINCIPAL",
              });
              handleClickCiudadPrincipal(ciudadPrincipal);
            }}
          />
        ))}

        {/* Ramales de la ciudad seleccionada */}
        {selectedCity?.ramales?.map((ramal, i) => (
          <Marker
            key={`ramal-${i}`}
            position={{ lat: ramal.lat, lng: ramal.lng }}
            icon={{
              url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png", // Ícono rojo de Google
            }}
            onClick={() => {
              handleClickMarker({
                name: ramal.name,
                tiempo: ramal.tiempo,
                lat: ramal.lat,
                lng: ramal.lng,
                tipo: ramal.tipo || "RAMAL",
              });
            }}
          />
        ))}

        {/* InfoWindow para el marcador seleccionado */}
        {selectedMarker && (
          <InfoWindow
            position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
            onCloseClick={handleCloseInfoWindow}
          >
            <div style={{ padding: "5px" }}>
              <h4 style={{ margin: "0 0 5px 0" }}>{selectedMarker.name}</h4>
              <p style={{ margin: "0" }}>Tiempo de tránsito: {selectedMarker.tiempo} hrs</p>
              <p style={{ margin: "0" }}>Tipo: {selectedMarker.tipo}</p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
};

export default MapaMatrizTransito;

import { useState, useEffect, useRef } from "react";
import {
  Box,
  Alert,
  AlertTitle,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress'
import obtenerTrazabilidadOD from "../../services/ConsultaPublicaService"
import BuscarOd from "../../components/ReportesComponentes/componentesConsultasPublicas/BuscarOD"
import ResponsableAppBar from "../../components/ReportesComponentes/componentesConsultasPublicas/ResponsableAppBar";
import DetalleOD from "../../components/ReportesComponentes/componentesConsultasPublicas/DetalleOD";

const tipoConsulta = [
  {type: "od", route:"app/cargoex/app/consultaOdApiSimple"},
  {type: "ruta", route:"app/cargoex/app/consultaOdApiSimple"},
]

function ConsultaPublica() {
  const [data, setData] = useState({});
  const [reqError, setReqError] = useState('');
  const [ciudadesPrincipales, setCiudadesPrincipales] = useState([]);
  const inputRef = useRef("");
  const params = new URL(window.location.href.toString()).searchParams;
  const od = params.get("od") || null;
  const queryType = params.get("type") || tipoConsulta[0].type

  /* Fetchear od's pasada por parametro*/
  useEffect(() => {
    if (queryType === tipoConsulta[0].type && od !== null && String(od).trim() !== "") {
      obtenerTrazabilidadOD(setData, setReqError, od)
    }
  }, [queryType,od]);

  /* Obtencion de cordenadas y filtro para cordenadas LONG:0 - LAT:0 */
  useEffect(() => {
    if (queryType === tipoConsulta[0].type && data.msj && Array.isArray(data.msj)) {
      const cities = data.msj.filter((element) => Number(element.LAT_TERRENO) !== 0 &&
       Number(element.LONG_TERRENO) !== 0).map((element) => ({
        lat: Number(element.LAT_TERRENO),
        lng: Number(element.LONG_TERRENO),
      }));
      setCiudadesPrincipales(cities);
    }
  }, [data,queryType]);

  /*Renderizar error cuando se realiza la busqueda.*/
  if (reqError) {
    return (
      <Alert severity="error" onClose={() => {
        window.location.reload()
        setReqError("")
      }}>
        <AlertTitle>Error</AlertTitle>
        Algo salió mal durante la solicitud: <strong>{reqError}</strong>
      </Alert>
    )
  };

  /* Loader */
  if (Object.keys(data).length === 0 && od) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  };

  /* Esconder side bar */
  if (document.querySelector("#side-bar")) {
    document.getElementById("side-bar").display = "none"
  };
  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gap: 2, // Usa el sistema de espaciado de MUI (equivale a 16px)
      }}
    >
      <ResponsableAppBar data={data} />
      <BuscarOd
        od={od}
        inputRef={inputRef}
        setData={setData}
        setReqError={setReqError}
      />
      <DetalleOD data={data} ciudadesPrincipales={ciudadesPrincipales} inputRef={inputRef}/>
      
    </Box>
  );
}

export default ConsultaPublica;
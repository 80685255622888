import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Paper, Typography, Avatar, IconButton, Button, TextField, Snackbar, Alert } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import UserProfileModal from "../../utils/UserProfileModal";
import { getManifiestoDetalleCarga, confirmacionBultos } from "../../services/operacionesService";
import cajas from "../../components/assets/img/CajasPila.png";
import caja from "../../components/assets/img/caja.png";
import sacos from "../../components/assets/img/Sacos.png";
import pallet from "../../components/assets/img/pallet.png";

const InicioAgente = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [fechaFormateada, setFechaFormateada] = useState("");
  const [toast, setToast] = useState({ open: false, message: "", severity: "" });
  const [confirmationStates, setConfirmationStates] = useState({});

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleToastClose = () => setToast({ open: false, message: "", severity: "" });

  const handleConfirm = async (id, total) => {
    setToast({ open: true, message: "Bultos confirmados correctamente.", severity: "success" });
    setConfirmationStates((prev) => ({ ...prev, [id]: { confirmed: true } }));
    const data = {
      ID: id,
      BULTOS: total,
    };
    const response = await confirmacionBultos(data);
    console.log(response);
  };

  const handleRectify = async (id, newValue, total, transporte, tipo_carga, agente) => {
    console.log(`Rectificación para ID ${id}: ${newValue}`);
    setToast({ open: true, message: "Bultos rectificados correctamente.", severity: "success" });
    setConfirmationStates((prev) => ({ ...prev, [id]: { confirmed: true } }));
    const data = {
      ID: id,
      BULTOS: newValue,
      TOTAL: total,
      TRANSPORTE: transporte,
      TIPO_CARGA: tipo_carga,
      AGENTE_ASIGNADO: agente,
    };
    const response = await confirmacionBultos(data);
    console.log(response);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getManifiestoDetalleCarga(userInfo.AGENTE_ASIGNADO);
        console.log("Data:", data);
        if (data.length === 0) {
          console.log("No hay datos disponibles.");
          setData([]);
          setFechaFormateada("");
          return;
        }
        setData(data);
        const fechaISO = data[0]?.FH_DIGITACION;
        const fecha = new Date(fechaISO);
        const fechaFormateada = `${fecha.getDate().toString().padStart(2, "0")}-${(fecha.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${fecha.getFullYear()}`;
        setFechaFormateada(fechaFormateada);
      } catch (error) {
        console.error("Error al cargar los datos:", error);
        setData([]);
        setFechaFormateada("");
      }
    };

    fetchData();
  }, [userInfo]);

  const dividerStyle = { height: "3px", backgroundColor: "#041562", margin: "5px 0", marginTop: 20 };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 1, minHeight: 100, position: "relative" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 1 }}>
            <Typography
              variant="poster"
              sx={{ fontFamily: "'Nunito', sans-serif", fontSize: "1.5rem", flexGrow: 1, textAlign: "center" }}
              gutterBottom
            >
              BIENVENIDO/A {userInfo.name}
            </Typography>
            <IconButton onClick={handleOpenModal} sx={{ flexShrink: 0 }}>
              <Avatar>
                <AccountCircleIcon style={{ color: "#041562" }} />
              </Avatar>
            </IconButton>
          </Box>
        </Paper>
        <Paper elevation={3} sx={{ p: 1, mt: 2 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "'Nunito', sans-serif", fontSize: "1.5rem", textAlign: "center", mb: 2 }}
          >
            DETALLE DE CARGA ENVIADA DESDE SANTIAGO EL {fechaFormateada}
          </Typography>
          {data.map((item, index) => (
            <React.Fragment key={item.ID}>
              {/* Recuadros para Transporte y Tipo Carga */}
              <Grid container spacing={2} justifyContent="center" sx={{ mb: 1 }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box sx={{ p: 0.5, textAlign: "center" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold", fontSize: "1rem" }}>
                      {item.TRANSPORTE} - {item.TIPO_CARGA}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              {/* Dashboard de valores */}
              <Grid container spacing={2} justifyContent="center" sx={{ mb: 1 }}>
                {/* Sacos */}
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Paper sx={{ p: 0.5, textAlign: "center" }}>
                    <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                      SACOS
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <img src={sacos} alt="Sacos" style={{ width: 24, height: 24, marginRight: 4 }} />
                      <Typography variant="h6" color="primary" sx={{ fontSize: "1.2rem" }}>
                        {item.SACOS}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
                {/* Cajas */}
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Paper sx={{ p: 0.5, textAlign: "center" }}>
                    <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                      CAJAS
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <img src={caja} alt="Caja" style={{ width: 24, height: 24, marginRight: 4 }} />
                      <Typography variant="h6" color="primary" sx={{ fontSize: "1.2rem" }}>
                        {item.BULTOS}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
                {/* Pallet */}
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Paper sx={{ p: 0.5, textAlign: "center" }}>
                    <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                      PALLET
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <img src={pallet} alt="Pallet" style={{ width: 24, height: 24, marginRight: 4 }} />
                      <Typography variant="h6" color="primary" sx={{ fontSize: "1.2rem" }}>
                        {item.PALLETS > 0 ? item.PALLETS : "-"}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
                {/* Total */}
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Paper sx={{ p: 0.5, textAlign: "center" }}>
                    <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                      TOTAL
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <img src={cajas} alt="Cajas" style={{ width: 24, height: 24, marginRight: 4 }} />
                      <Typography variant="h6" color="primary" sx={{ fontSize: "1.2rem" }}>
                        {item.TOTAL}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                {item.TOTAL_RECEPCIONADO !== 0 ? (
                  // Si TOTAL_RECEPCIONADO es diferente de 0, muestra el estado completado
                  <Typography variant="body2" color="primary" sx={{ textAlign: "right" }}>
                    Total Recepcionado: {item.TOTAL_RECEPCIONADO}
                  </Typography>
                ) : // Si TOTAL_RECEPCIONADO es 0, muestra la lógica de confirmación o rectificación
                !confirmationStates[item.ID]?.confirmed ? (
                  confirmationStates[item.ID]?.rectify ? (
                    <Box sx={{ display: "flex", justifyContent: "right", alignItems: "center", gap: 2 }}>
                      <TextField
                        label="Cantidad Recibida"
                        variant="outlined"
                        size="small"
                        type="number"
                        value={confirmationStates[item.ID]?.newValue || ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          setConfirmationStates((prev) => ({
                            ...prev,
                            [item.ID]: { ...prev[item.ID], newValue: value },
                          }));
                        }}
                      />
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                          const newValue = confirmationStates[item.ID]?.newValue;
                          if (newValue && newValue > 0) {
                            handleRectify(
                              item.ID,
                              newValue,
                              item.TOTAL,
                              item.TRANSPORTE,
                              item.TIPO_CARGA,
                              userInfo.AGENTE_ASIGNADO,
                            );
                          } else {
                            setToast({
                              open: true,
                              message: "Por favor, ingrese una cantidad válida.",
                              severity: "warning",
                            });
                          }
                        }}
                      >
                        Enviar
                      </Button>
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", justifyContent: "right", alignItems: "center", gap: 2 }}>
                      <Button variant="contained" color="success" onClick={() => handleConfirm(item.ID, item.TOTAL)}>
                        Confirmar
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() =>
                          setConfirmationStates((prev) => ({
                            ...prev,
                            [item.ID]: { rectify: true, newValue: "" },
                          }))
                        }
                      >
                        INFORMAR DIFERENCIA
                      </Button>
                    </Box>
                  )
                ) : (
                  <Typography variant="body2" color="primary" sx={{ textAlign: "center" }}>
                    Acción completada
                  </Typography>
                )}
              </Grid>

              {/* Nota debajo */}
              {item.NOTA && (
                <Box item xs={12} sm={12} md={9} lg={9}>
                  <Box sx={{ p: 0.5, textAlign: "center" }}>
                    <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.85rem" }}>
                      DETALLE
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                      {item.NOTA}
                    </Typography>
                  </Box>
                </Box>
              )}

              {index !== data.length - 1 && <div style={dividerStyle}></div>}
            </React.Fragment>
          ))}
        </Paper>
        <UserProfileModal open={openModal} handleClose={handleCloseModal} userInfo={userInfo} />
        <Snackbar
          open={toast.open}
          autoHideDuration={3000}
          onClose={handleToastClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleToastClose} severity={toast.severity} sx={{ width: "100%" }}>
            {toast.message}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
};

export default InicioAgente;

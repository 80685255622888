import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, Button, CircularProgress } from "@mui/material";
import axios from "axios";
import { calcularCostos } from "../../services/CalculadoraService";
import { comunas } from "../../utils/ComunasCalculadora";
import { GoogleMap, Marker, DirectionsService, DirectionsRenderer, useLoadScript } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "360px",
};

const rowStyle = {
  display: "flex",
  gap: "16px",
  marginBottom: "12px",
};

const itemStyle = {
  flex: 1,
};

const ICON_ORIGEN = "http://maps.google.com/mapfiles/ms/icons/green-dot.png";
const ICON_DESTINO = "http://maps.google.com/mapfiles/ms/icons/red-dot.png";

const CalculadoraCostos = () => {
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [etiquetas, setEtiquetas] = useState([]);
  const [marcaSeleccionada, setMarcaSeleccionada] = useState(null);
  const [modeloSeleccionado, setModeloSeleccionado] = useState(null);
  const [etiquetaSeleccionada, setEtiquetaSeleccionada] = useState(null);
  const [resultado, setResultado] = useState(null);
  const [cargando, setCargando] = useState(false);
  const [error, setError] = useState("");
  const [comunaOrigen, setComunaOrigen] = useState(null);
  const [comunaDestino, setComunaDestino] = useState(null);
  const [origenPos, setOrigenPos] = useState(null);
  const [destinoPos, setDestinoPos] = useState(null);
  const [directions, setDirections] = useState(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBITfQPAHSXfc_Lw09kwU1TlwJVbJJwMtY",
  });

  useEffect(() => {
    const cargarMarcas = async () => {
      try {
        const response = await axios.get("https://www.consumovehicular.cl/backend/scv/vehiculo/marcas");
        setMarcas(response.data);
      } catch (error) {
        console.error("Error al cargar marcas:", error);
      }
    };
    cargarMarcas();
  }, []);

  useEffect(() => {
    if (!marcaSeleccionada) {
      setModelos([]);
      setModeloSeleccionado(null);
      return;
    }
    const cargarModelos = async () => {
      try {
        const response = await axios.get("https://www.consumovehicular.cl/backend/scv/vehiculo/modelos", {
          params: { idMarca: marcaSeleccionada.idMarca },
        });
        setModelos(response.data);
      } catch (error) {
        console.error("Error al cargar modelos:", error);
      }
    };
    cargarModelos();
  }, [marcaSeleccionada]);

  useEffect(() => {
    if (!modeloSeleccionado) {
      setEtiquetas([]);
      setEtiquetaSeleccionada(null);
      return;
    }
    const cargarEtiquetas = async () => {
      try {
        const response = await axios.get("https://www.consumovehicular.cl/backend/scv/vehiculo/etiquetas", {
          params: { idModelo: modeloSeleccionado.idModelo },
        });
        setEtiquetas(response.data);
      } catch (error) {
        console.error("Error al cargar etiquetas:", error);
      }
    };
    cargarEtiquetas();
  }, [modeloSeleccionado]);

  const handleCalcular = async () => {
    if (!marcaSeleccionada || !modeloSeleccionado || !etiquetaSeleccionada) {
      setError("Por favor selecciona todos los campos.");
      return;
    }

    try {
      setCargando(true);
      setError("");

      const criterio = `idMarca:EQ:${marcaSeleccionada.idMarca};idModelo:EQ:${modeloSeleccionado.idModelo};idEtiqueta:EQ:${etiquetaSeleccionada.idEtiqueta}`;
      const response = await axios.get("https://www.consumovehicular.cl/backend/scv/vehiculo", {
        params: {
          criterio,
          page: 0,
          size: 3,
          sort: "nombreMarca,nombreModelo",
        },
      });
      const vehiculoData = response.data.content[0];

      if (!vehiculoData) {
        setError("No se puede calcular el rendimiento para este vehículo.");
        return;
      }

      const rendimientoMixto =
        vehiculoData.rendimientoMixto ??
        vehiculoData.rendimientoCarretera ??
        vehiculoData.rendimientoUrbano ??
        vehiculoData.rendimientoElectrico;

      if (!rendimientoMixto) {
        setError("No se pudo obtener el rendimiento del vehículo.");
        return;
      }

      if (!comunaOrigen || !comunaDestino) {
        setError("Por favor selecciona tanto la comuna de origen como la comuna de destino.");
        return;
      }

      const data = {
        idMarca: marcaSeleccionada.idMarca,
        idModelo: modeloSeleccionado.idModelo,
        idEtiqueta: etiquetaSeleccionada.idEtiqueta,
        rendimiento: rendimientoMixto,
        ciudadOrigen: comunaOrigen.nombre,
        ciudadDestino: comunaDestino.nombre,
      };

      const resp = await calcularCostos(data);
      setResultado(resp);
    } catch (error) {
      console.error("Error al calcular costos:", error);
      setError("Ocurrió un error al calcular los costos.");
    } finally {
      setCargando(false);
    }
  };

  useEffect(() => {
    if (resultado?.origenLat && resultado?.origenLng) {
      setOrigenPos({
        lat: Number(resultado.origenLat),
        lng: Number(resultado.origenLng),
      });
    }
    if (resultado?.destinoLat && resultado?.destinoLng) {
      setDestinoPos({
        lat: Number(resultado.destinoLat),
        lng: Number(resultado.destinoLng),
      });
    }

    setDirections(null);
  }, [resultado]);

  const center = origenPos || { lat: -33.4724727, lng: -70.9100251 };

  const handleDirectionsCallback = (res) => {
    if (res !== null) {
      if (res.status === "OK") {
        setDirections(res);
      } else {
        console.error("Directions request failed: ", res.status);
      }
    }
  };

  return (
    <div style={{ maxWidth: 1200, margin: "auto", padding: 16 }}>
      <div style={rowStyle}>
        <div style={itemStyle}>
          <Autocomplete
            options={comunas}
            getOptionLabel={(option) => (option ? option.nombre : "")}
            value={comunaOrigen}
            onChange={(event, newValue) => setComunaOrigen(newValue)}
            renderInput={(params) => <TextField {...params} label="Origen" variant="outlined" size="small" />}
          />
        </div>

        <div style={itemStyle}>
          <Autocomplete
            options={comunas}
            getOptionLabel={(option) => (option ? option.nombre : "")}
            value={comunaDestino}
            onChange={(event, newValue) => setComunaDestino(newValue)}
            renderInput={(params) => <TextField {...params} label="Destino" variant="outlined" size="small" />}
          />
        </div>

        <div style={itemStyle}>
          <Autocomplete
            options={marcas}
            getOptionLabel={(option) => option.nombre || ""}
            isOptionEqualToValue={(option, value) => option.idMarca === value.idMarca}
            value={marcaSeleccionada}
            onChange={(event, newValue) => setMarcaSeleccionada(newValue)}
            renderInput={(params) => <TextField {...params} label="Marca" variant="outlined" size="small" />}
          />
        </div>
      </div>

      <div style={rowStyle}>
        <div style={itemStyle}>
          <Autocomplete
            options={modelos}
            getOptionLabel={(option) => option.nombre || ""}
            value={modeloSeleccionado}
            onChange={(event, newValue) => setModeloSeleccionado(newValue)}
            renderInput={(params) => <TextField {...params} label="Modelo" variant="outlined" size="small" />}
          />
        </div>

        <div style={itemStyle}>
          <Autocomplete
            options={etiquetas}
            getOptionLabel={(option) => option.nombre || ""}
            value={etiquetaSeleccionada}
            onChange={(event, newValue) => setEtiquetaSeleccionada(newValue)}
            renderInput={(params) => <TextField {...params} label="Etiqueta" variant="outlined" size="small" />}
          />
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            disabled={cargando}
            onClick={handleCalcular}
            style={{
              backgroundColor: cargando ? "gray" : "#041562",
              color: "#fff",
              cursor: cargando ? "not-allowed" : "pointer",
            }}
          >
            {cargando ? <CircularProgress size={24} color="inherit" /> : "Calcular"}
          </Button>
        </div>
      </div>

      {error && <p style={{ color: "red", textAlign: "center", marginTop: 8 }}>{error}</p>}

      {resultado && (
        <div style={{ marginTop: 16 }}>
          {!isLoaded && <p>Cargando mapa...</p>}

          {isLoaded && (
            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={7}>
              {origenPos && destinoPos && !directions && (
                <DirectionsService
                  options={{
                    origin: origenPos,
                    destination: destinoPos,
                    travelMode: "DRIVING",
                  }}
                  callback={handleDirectionsCallback}
                />
              )}

              {directions && <DirectionsRenderer directions={directions} />}

              {origenPos && (
                <Marker
                  position={origenPos}
                  icon={{
                    url: ICON_ORIGEN,
                  }}
                />
              )}
              {destinoPos && (
                <Marker
                  position={destinoPos}
                  icon={{
                    url: ICON_DESTINO,
                  }}
                />
              )}
            </GoogleMap>
          )}
          <div
            style={{
              backgroundColor: "#041562",
              color: "#fff",
              marginTop: 16,
              padding: "1rem",
              borderRadius: 8,
            }}
          >
            <h4 style={{ textAlign: "center", marginBottom: "1rem" }}>Resultados del Cálculo (IDA)</h4>

            <div
              style={{
                display: "flex",
                gap: "2rem",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>Distancia: {resultado.distanciaKm} km</div>
              <div>Litros consumidos: {resultado.litrosConsumidos?.toFixed(2)} L</div>
              <div>Precio por litro: ${resultado.precioPorLitro}</div>
              <div>Rendimiento del vehiculo: {resultado.rendimientoVehiculo}</div>
              <div>
                <b>Costo total: ${Number(resultado.costoTotal).toFixed(0)}</b>
              </div>
            </div>

            <p
              style={{
                marginTop: "1rem",
                fontSize: "0.9rem",
                textAlign: "center",
              }}
            >
              <b>Nota:</b> Estos costos corresponden únicamente a la ida. Recuerda considerar gastos adicionales como
              peajes y viáticos.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalculadoraCostos;

import React from "react";
import TableKpi from "./tablekpis";

const MostrarTablaMultipleResumen = ({ stats }) => {
  if (!stats || !stats.listaClientes) return null;

  const columns = [
    { field: "nombreCliente", headerName: "Cliente", flex: 1 },
    { field: "totalOrdenes", headerName: "Total Órdenes", flex: 0.5 },
    { field: "totalEntregas", headerName: "Entregas", flex: 0.5 },
    { field: "totalRetiros", headerName: "Retiros", flex: 0.5 },
    { field: "entregasExitosas", headerName: "Entregas Exitosas", flex: 0.7 },
    { field: "entregasNoExitosas", headerName: "Entregas No Exitosas", flex: 0.7 },
    { field: "retirosExitosos", headerName: "Retiros Exitosos", flex: 0.7 },
    { field: "retirosNoExitosos", headerName: "Retiros No Exitosos", flex: 0.7 },
    { field: "slaPct", headerName: "SLA (%)", flex: 0.5 },
    { field: "kpiPct", headerName: "KPI (%)", flex: 0.5 },
  ];

  const rows = stats.listaClientes.map((cliente, index) => ({
    id: index,
    nombreCliente: cliente.nombreCliente,
    totalOrdenes: cliente.totalOrdenes,
    totalEntregas: cliente.totalEntregas,
    totalRetiros: cliente.totalRetiros,
    entregasExitosas: cliente.entregasExitosas,
    entregasNoExitosas: cliente.entregasNoExitosas,
    retirosExitosos: cliente.retirosExitosos,
    retirosNoExitosos: cliente.retirosNoExitosos,
    slaPct: cliente.slaPct,
    kpiPct: cliente.kpiPct,
  }));

  return (
    <div style={{ marginTop: "20px" }}>
      <TableKpi columns={columns} rows={rows} loading={false} />
    </div>
  );
};

export default MostrarTablaMultipleResumen;

import {
  toastCabecera,
  sinFecha,
  sinCliente,
} from "./Alertas";
import { 
  //obtenerCiudadesHomologadas,
  obtenerCiudadesHomologadas2 } from "../../../services/RetirosIntegracionServices";
import Backdrop from "@mui/material/Backdrop";
import { RedTransparentBackground } from "./Progress";

export const limiteDeFilas = (row) => {
  for (const column of Object.values(row)) {
    if (column) {
      return true;
    }
  }
  return false;
};

export const ValidarHomologacionCiudades = async (
  comuna,
  arrayComunasInvalidas
) => {
  try {
    await obtenerCiudadesHomologadas2(comuna);
  } catch {
    if (!arrayComunasInvalidas.includes(comuna)) {
      arrayComunasInvalidas.push(comuna);
    }
  }
};


export const ValidadorArchivoAdmin = (headersMatch, codigo, fechaRetiros) => {
  const condition2 = fechaRetiros !== "";
  const condition3 = codigo !== "";
  const condition5 = headersMatch;

  if (!condition2) {
    return sinFecha();
  } else if (!condition3) {
    return sinCliente();
  } else if (!condition5) {
    return toastCabecera();
  } else {
    return true;
  }
};

export const LinearProgressOverlay = ({ isVisible, progress, completed }) => {
  return (
    <div>
      <Backdrop
        open={isVisible}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: "#fff" }}
      >
        <RedTransparentBackground progress={progress} completed={completed} />
      </Backdrop>
    </div>
  );
};

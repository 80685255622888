import axios from "axios";
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;
const API_KEY = "55IcsddHxiy2E3q653RpYtb";

/* obtener centro de costo tipo de negocio y canal */
export const obtenerDatosCliente = async (cod_cliente) => {
  try {
    const response = await axios.post(`${BASE_API_URL}ordenes/obtenerDatosPorCliente`, { cod_cliente });
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos consolidados", error);
    throw error;
  }
};

/* obtener clientes */
export const obtenerClientes = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}ordenes/obtenerTodosClientes`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* obtener ciudades */
export const obtenerCiudades = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}ordenes/obtenerTodasLasCiudades`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* buscar manifiesto */
export const buscarManifiestos = async ({ searchValue }) => {
  try {
    const response = await axios.post(`${BASE_API_URL}ordenes/obtenerManifiestosPorOD`, {
      searchValue,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* buscar movimientos para orden */
export const buscarMovimientos = async ({ searchValue }) => {
  try {
    const response = await axios.post(`${BASE_API_URL}ordenes/obtenerMovimientos`, {
      searchValue,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* buscar od */
export const buscarOrdenes = async ({ searchValue }) => {
  try {
    const response = await axios.post(`${BASE_API_URL}ordenes/buscarOrdenes`, {
      searchValue,
    });
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos consolidados", error);
    throw error;
  }
};

export const editOrdenData = async (ID, editedData) => {
  try {
    const {
      OD,
      OD_PAPEL,
      ID_REFERENCIA,
      COD_BARRA,
      FH_DIGITACION,
      CUIDAD_ORIGEN,
      CUIDAD_DESTINO,
      COD_CLIENTE,
      NOMBRE_CLIENTE,
      CANAL,
      TIPO_NEGOCIO,
      TIPO_ORDEN,
      VIA,
      TIPO_CARGA,
      RUT,
      NOMBRE,
      DIRECCION,
      MAIL,
      TELEFONO,
      CENTRO_COSTO,
      CONTACTO_DESTINO,
      TELEFONO_ORIGEN,
      CONTACTO,
      DIRECCION_RETIRO,
      NOMBRE_RETIRO,
      BULTOS,
      PESO,
      ALTO,
      LARGO,
      ANCHO,
      PESO_VOLUMEN,
      NOTA,
      GUIA,
      CANTIDAD_NEVERA,
      USUARIO,
    } = editedData;

    const data = {
      OD,
      OD_PAPEL,
      ID_REFERENCIA,
      COD_BARRA,
      COD_CLIENTE,
      FH_DIGITACION,
      CUIDAD_ORIGEN,
      CUIDAD_DESTINO,
      NOMBRE_CLIENTE,
      CANAL,
      TIPO_NEGOCIO,
      TIPO_ORDEN,
      VIA,
      TIPO_CARGA,
      RUT,
      NOMBRE,
      DIRECCION,
      MAIL,
      TELEFONO,
      CENTRO_COSTO,
      CONTACTO_DESTINO,
      TELEFONO_ORIGEN,
      CONTACTO,
      DIRECCION_RETIRO,
      NOMBRE_RETIRO,
      BULTOS,
      PESO,
      ALTO,
      LARGO,
      ANCHO,
      PESO_VOLUMEN,
      NOTA,
      GUIA,
      CANTIDAD_NEVERA,
      USUARIO,
    };
    const response = await axios.put(`${BASE_API_URL}ordenes/editOrdenes/${ID}`, data);
    return response.data;
  } catch (error) {
    console.error("Error editando datos de la orden", error);
    throw error;
  }
};

export const buscarCTC = async ({ idCliente }) => {
  try {
    const response = await axios.post(`${BASE_API_URL}ordenes/buscarCTC`, {
      idCliente,
    });
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos consolidados", error);
    throw error;
  }
};

export const buscarCanal = async ({ idCliente }) => {
  try {
    const response = await axios.post(`${BASE_API_URL}ordenes/buscarCanal`, {
      idCliente,
    });
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos consolidados", error);
    throw error;
  }
};

export const getClientAddresses = async (clientId) => {
  try {
    const response = await axios.get(`${BASE_API_URL}ordenes/obtenerDirecciones`, {
      params: {
        cod_cliente: clientId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching client addresses:", error);
    throw error;
  }
};

export const obtenerEtiqueta = async ({ OD }) => {
  try {
    const response = await axios.post(
      "https://app.cargoex.cl/app/cargoex/app/verEtiqueta",
      { OD },
      {
        headers: {
          "X-API-KEY": API_KEY,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error al generar etiqueta", error);
    throw error;
  }
};

export const EtiquetaObtener = async (od) => {
  try {
    const response = await axios.post(`${BASE_API_URL}impresionEtiqueta/obtenerEtiqueta/${od}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const obtenerMovimientos = async ({ od }) => {
  try {
    const response = await axios.post(`${BASE_API_URL}ordenes/obtenerMovimientos?od=${od}`);

    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos consolidados", error);
    throw error;
  }
};

export const obtenerTodo = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}ordenes/data-all`);
    return response.data;
  } catch (error) {
    console.error("Error fetching obtenerTodo:", error);
    throw error;
  }
};

/* modificar fechas masivo ordenes */
export const modificarFechasAPI = async (datosModificacion, idUsuario) => {
  try {
    const response = await axios.post(`${BASE_API_URL}ordenes/modificarFechasMasivo`, {
      datos: datosModificacion,
      USUARIO: idUsuario,
    });
    return response.data;
  } catch (error) {
    console.error("Error modificando fechas de las órdenes", error);
    throw error;
  }
};

/* eliminar od */
export const eliminarOrdenAPI = async (odPapel, idUsuario) => {
  try {
    const response = await axios.delete(`${BASE_API_URL}ordenes/eliminarOrdenes/${odPapel}`, {
      params: { USUARIO: idUsuario },
    });
    return response.data;
  } catch (error) {
    console.error("Error eliminando la orden", error);
    throw error;
  }
};

/* eliminar od masivamente */
export const eliminarOrdenMasivo = async (ods, idUsuario) => {
  try {
    const odsString = ods.join(",");
    const response = await axios.delete(`${BASE_API_URL}ordenes/eliminarOrdenesMasivo`, {
      params: { ods: odsString, USUARIO: idUsuario },
    });
    return response.data;
  } catch (error) {
    console.error("Error eliminando las órdenes", error);
    throw error;
  }
};

/* recuperar od */
export const recuperarOrden = async (odPapel, idUsuario) => {
  try {
    const response = await axios.post(`${BASE_API_URL}ordenes/recuperarOrden/${odPapel}`, {
      params: { USUARIO: idUsuario },
    });
    return response.data;
  } catch (error) {
    console.error("Error recuperando la orden", error);
    throw error;
  }
};

export const obtenerMovimientosPage = async (od) => {
  try {
    const response = await axios.post(`${BASE_API_URL}movimientos/obtenerMovimientos?od=${od}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createOrderAPI = async (formData) => {
  try {
    const response = await axios.post("https://app.cargoex.cl/app/cargoex/app/despacho", formData, {
      headers: {
        "X-API-KEY": "55IcsddHxiy2E3q653RpYtb",
      },
    });

    if (response.status !== 200) {
      throw new Error("Error al crear la orden");
    }
    return response.data;
  } catch (error) {
    console.error("Hubo un error al hacer la solicitud:", error);
    throw error;
  }
};

/* Actualizamos Via con excel */
export const updateVia = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}ordenes/getUpdateVia`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching getUpdateVia:", error);
    throw error;
  }
};

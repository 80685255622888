import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

/* obtenemos Vehiculos */
export const obtenerVehiculos = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}vehiculos/obtenerVehiculos`);
      return response.data;
    } catch (error) {
      throw error;
    } 
  };

  /* actualizar Vehiculos */
export const actualizarVehiculos = async (id, vehiculosData) => {
    try {
      const response = await axios.put(`${BASE_API_URL}vehiculos/actualizarVehiculos/${id}`, vehiculosData);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  /* crear Vehiculos */
  export const crearVehiculos = async (vehiculosData) => {
    try {
      const response = await axios.post(`${BASE_API_URL}vehiculos/crearVehiculos`, vehiculosData);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  /* eliminar Vehiculos */
  export const eliminarVehiculos = async (id) => {
    try {
      const response = await axios.delete(`${BASE_API_URL}vehiculos/eliminarVehiculos/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const obtenerMarcasVehiculo = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}vehiculos/obtenerMarcasVehiculo`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const obtenerModelosVehiculo = async (id) => {
    try {
      const response = await axios.get(`${BASE_API_URL}vehiculos/obtenerModelosVehiculo/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  /* obtenemos Ciudades */
export const obtenerCiudades = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}vehiculos/obtenerCiudades`);
    return response.data;
  } catch (error) {
    throw error;
  } 
};
import React, { useState, useEffect, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  InputAdornment,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  actualizarChoferes,
  actualizarPasswordUsuario,
  obtenerClientes,
  obtenerAgentesNegocios,
} from "../../../services/PortalChoferesService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Autocomplete from "@mui/material/Autocomplete";
import { obtenerComunas } from "../../../services/EventosManualServices";

const EditModalChoferes = ({ open, onClose, choferes, onSave, campos }) => {
  const [formData, setFormData] = useState({});
  const [clientes, setClientes] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [agentesNegocios, setAgentesNegocios] = useState([]);
  const [comunas, setComunas] = useState([]);

  useEffect(() => {
    setFormData({
      ...choferes,
      AGENTE_ASIGNADO: choferes?.AGENTE_ASIGNADO || "",
    });
  }, [choferes]);

  useEffect(() => {
    const cargarClientes = async () => {
      try {
        const listaClientes = await obtenerClientes();
        setClientes(listaClientes);
        setSelectedClient(listaClientes[0] || null);
      } catch (error) {
        console.error("Error al obtener los clientes:", error);
        toast.error("Error al cargar la lista de clientes.");
      }
    };
    cargarClientes();
  }, []);

  useEffect(() => {
    const cargarAgentesNegocios = async () => {
      try {
        const listaAgentes = await obtenerAgentesNegocios();
        setAgentesNegocios(listaAgentes);
      } catch (error) {
        console.error("Error al obtener los agentes de negocios:", error);
        toast.error("Error al cargar la lista de agentes de negocios.");
      }
    };
    cargarAgentesNegocios();
  }, []);

  useEffect(() => {
    const cargarComunas = async () => {
      try {
        const comunasObtenidas = await obtenerComunas();
        setComunas(comunasObtenidas);
      } catch (error) {
        console.error("Error al obtener comunas:", error);
        toast.error("Error al cargar la lista de comunas.");
      }
    };
    cargarComunas();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    const formDataConClienteId = selectedClient ? { ...formData, cod_cliente: selectedClient.ID } : { ...formData };
    const choferId = formDataConClienteId.ID;
    try {
      await actualizarChoferes(choferId, formDataConClienteId);
      onSave(formDataConClienteId);
    } catch (error) {
      console.error("Error al guardar los cambios", error);
    }
    onClose();
  };

  const handleEditPasswordClick = async () => {
    const campoPassword = "PASSWORD";
    const nuevaPassword = formData[campoPassword];
    if (!nuevaPassword || nuevaPassword.length < 6) {
      toast.error("La contraseña debe tener al menos 6 caracteres");
      return;
    }
    try {
      const response = await actualizarPasswordUsuario(nuevaPassword);
      const { hash } = response;
      setFormData((prevFormData) => ({
        ...prevFormData,
        PASSWORD_HASH: hash,
      }));
      toast.success("Hash actualizado con éxito");
    } catch (error) {
      console.error("Error al actualizar el Hash:", error);
      toast.error("Error al actualizar el Hash");
    }
  };

  const DeleteTokenFecha = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      TOKEN_SESION: "",
      FH_SYS: "",
    }));
  };

  const agentesFiltrados = useMemo(() => {
    if (!formData.CIUDAD) {
      return agentesNegocios;
    }
    return agentesNegocios.filter((agent) => agent.iata_agentes === formData.CIUDAD);
  }, [agentesNegocios, formData.CIUDAD]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Editar choferes</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {campos.map((campo, index) => (
            <Grid item xs={12} key={index}>
              {campo.nombre === "AGENTE_ASIGNADO" ? (
                <Autocomplete
                  id="combo-box-agente"
                  options={agentesFiltrados}
                  getOptionLabel={(option) => (option ? `${option.name} (${option.iata_agentes})` : "")}
                  value={
                    agentesFiltrados.find(
                      (agent) => `${agent.name} (${agent.iata_agentes})` === formData.AGENTE_ASIGNADO,
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      AGENTE_ASIGNADO: newValue ? `${newValue.name} (${newValue.iata_agentes})` : "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      style={{ marginTop: "10px" }}
                      {...params}
                      label="Agente Asignado"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              ) : campo.nombre === "cod_cliente" ? (
                <Autocomplete
                  id="combo-box-cliente"
                  options={clientes}
                  getOptionLabel={(option) => `${option.NOMBRE} (COD: ${option.ID})`}
                  isOptionEqualToValue={(option, value) => option.ID === value.ID}
                  value={selectedClient}
                  onChange={(event, newValue) => {
                    setSelectedClient(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label="Cliente" variant="outlined" fullWidth />}
                />
              ) : campo.nombre === "iata_sucursal" || campo.nombre === "iata_agentes" || campo.nombre === "CIUDAD" ? (
                <Autocomplete
                  id="combo-box-comunas"
                  options={comunas}
                  getOptionLabel={(option) => `${option.NOMBRE} (${option.IATA})`}
                  isOptionEqualToValue={(option, value) => option.IATA === value.IATA}
                  value={comunas.find((comuna) => comuna.IATA === formData[campo.nombre]) || null}
                  onChange={(event, newValue) => {
                    setFormData((prev) => ({
                      ...prev,
                      [campo.nombre]: newValue ? newValue.IATA : "",
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label={campo.etiqueta} fullWidth />
                  )}
                />
              ) : campo.nombre === "PASSWORD_HASH" ? (
                <TextField
                  fullWidth
                  label={campo.etiqueta}
                  name={campo.nombre}
                  value={formData[campo.nombre] || ""}
                  disabled
                  variant="outlined"
                />
              ) : campo.nombre === "PASSWORD" ? (
                <TextField
                  fullWidth
                  label={campo.etiqueta}
                  name={campo.nombre}
                  value={formData[campo.nombre] || ""}
                  onChange={handleChange}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password edit" onClick={handleEditPasswordClick}>
                          <EditIcon style={{ color: "#041562" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : campo.nombre === "TOKEN_SESION" ? (
                <TextField
                  fullWidth
                  label={campo.etiqueta}
                  name={campo.nombre}
                  value={formData[campo.nombre] || ""}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle delete token" onClick={DeleteTokenFecha}>
                          <DeleteForeverIcon style={{ color: "#DA251C" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : campo.nombre === "FH_SYS" ? (
                <TextField
                  fullWidth
                  label={campo.etiqueta}
                  name={campo.nombre}
                  value={formData[campo.nombre] || ""}
                  disabled
                  variant="outlined"
                />
              ) : campo.nombre === "token" ? (
                <TextField
                  fullWidth
                  label={campo.etiqueta}
                  name={campo.nombre}
                  value={formData[campo.nombre] || ""}
                  disabled
                  variant="outlined"
                />
              ) : campo.nombre === "password" ? (
                <TextField
                  fullWidth
                  label={campo.etiqueta}
                  name={campo.nombre}
                  value={formData[campo.nombre] || ""}
                  disabled
                  variant="outlined"
                />
              ) : campo.nombre === "password_palabras" ? (
                <TextField
                  fullWidth
                  label={campo.etiqueta}
                  name={campo.nombre}
                  value={formData[campo.nombre] || ""}
                  onChange={handleChange}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password edit" onClick={handleEditPasswordClick}>
                          <EditIcon style={{ color: "#041562" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : campo.nombre === "TRANSPORTISTA" ||
                campo.nombre === "PERMITE_RETIROS" ||
                campo.nombre === "PERMITE_IMPRESORA" ||
                campo.nombre === "PERMITE_ENTREGAS" ||
                campo.nombre === "PERMITE_INSPECCION" ||
                campo.nombre === "PERMITE_RETORNOS" ||
                campo.nombre === "PERMITE_APP" ||
                campo.nombre === "permite_rebaja_manual" ||
                campo.nombre === "permite_rebaja_masiva" ||
                campo.nombre === "permite_modificacion_orden" ? (
                <FormControl fullWidth>
                  <InputLabel>{campo.etiqueta}</InputLabel>
                  <Select
                    label={campo.etiqueta}
                    name={campo.nombre}
                    value={formData[campo.nombre] || ""}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    <MenuItem value="SI">SI</MenuItem>
                    <MenuItem value="NO">NO</MenuItem>
                  </Select>
                </FormControl>
              ) : campo.nombre === "ESTADO" ? (
                <FormControl fullWidth>
                  <InputLabel>{campo.etiqueta}</InputLabel>
                  <Select
                    label={campo.etiqueta}
                    name={campo.nombre}
                    value={formData[campo.nombre] || ""}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    <MenuItem value="ACTIVO">ACTIVO</MenuItem>
                    <MenuItem value="INACTIVO">INACTIVO</MenuItem>
                  </Select>
                </FormControl>
              ) : campo.nombre === "tipo_usuario" ? (
                <FormControl fullWidth>
                  <InputLabel>{campo.etiqueta}</InputLabel>
                  <Select
                    label={campo.etiqueta}
                    name={campo.nombre}
                    value={formData[campo.nombre] || ""}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    <MenuItem value="GERENCIA">GERENCIA</MenuItem>
                    <MenuItem value="ADMIN">ADMIN</MenuItem>
                    <MenuItem value="SAC">SAC</MenuItem>
                    <MenuItem value="SUPERVISORA">SUPERVISORA</MenuItem>
                    <MenuItem value="SUPERVISOR">SUPERVISOR OPERACIONES</MenuItem>
                    <MenuItem value="OPERACIONES">OPERACIONES</MenuItem>
                    <MenuItem value="FACTURACION">FACTURACION</MenuItem>
                    <MenuItem value="CLIENTE">CLIENTE</MenuItem>
                    <MenuItem value="AGENTE">AGENTE</MenuItem>
                    <MenuItem value="AGENTE_HUB">AGENTE_HUB</MenuItem>
                    <MenuItem value="AGENTE_ADMIN">AGENTE_ADMIN</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  fullWidth
                  label={campo.etiqueta}
                  name={campo.nombre}
                  value={formData[campo.nombre] || ""}
                  onChange={handleChange}
                  variant="outlined"
                />
              )}
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSave}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModalChoferes;

import React, { useState } from "react";
import { Paper, Grid, TextField, Button, Container } from "@mui/material";
import { toast } from "react-toastify";
import { getManifiesto, deletePicking, deleteManifiesto } from "../../services/ManifiestosServices";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import TablaGestorManifiesto from "../../components/OperacionesComponentes/componentesManifiestos/TablaGestorManifiesto";

function GestorManifiestoSalida() {
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_user = userInfo ? userInfo.id : null;
  const [manifiestoId, setManifiestoId] = useState("");
  const [ordenesDataTabla, setOrdenesDataTabla] = useState([]);
  const [selectedItems, setSelectedItems] = useState(new Set());

  const handleChange = (event) => {
    setManifiestoId(event.target.value);
  };

  const handleBuscar = async () => {
    if (!manifiestoId.trim()) {
      toast.error("Debe ingresar un número de manifiesto");
      return;
    }
    const datos = {
      ID_MANIFIESTO: manifiestoId,
      //PROCESO: "SALIDAANDEN",
    };

    const data = await getManifiesto(datos);

    if (data.length > 0) {
      setOrdenesDataTabla(data);
    } else {
      toast.error("Manifiesto no existe");
      return;
    }
  };

  const handleEliminar = async () => {
    if (selectedItems.size === 0) {
      toast.error("Debe seleccionar al menos una orden para eliminar");
      return;
    }
    const ordenesArray = Array.from(selectedItems);

    const datos = {
      ordenes: ordenesArray,
      ID_MANIFIESTO: manifiestoId,
      usuario: id_user,
      PROCESO: "SALIDAANDEN",
    };

    Swal.fire({
      title: "Seguro de Eliminar?",
      text: "Está a punto de eliminar las órdenes seleccionadas del manifiesto!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, Eliminar!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deletePicking(datos);
        if (response.success) {
          Swal.fire({
            title: "Órdenes eliminadas",
            icon: "success",
          });
          const datos = {
            ID_MANIFIESTO: manifiestoId,
            PROCESO: "SALIDAANDEN",
          };
          const data = await getManifiesto(datos);
          console.log(data);
          if (data.length > 0) {
            setOrdenesDataTabla(data);
          } else {
            await deleteManifiesto(datos);
            setOrdenesDataTabla([]);
            setManifiestoId("");
          }
          setSelectedItems(new Set());
        } else {
          toast.error("No se pudieron eliminar las órdenes");
        }
      }
    });
  };

  return (
    <div>
      <Container maxWidth="lg">
      <Paper style={{ padding: "10px", marginBottom: "10px" }}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <TextField
              label="Número de Manifiesto"
              variant="outlined"
              value={manifiestoId}
              type="search"
              size="small"
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Button
              variant="contained"
              onClick={handleBuscar}
              sx={{
                backgroundColor: "#041562",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#030e4f",
                },
              }}
            >
              BUSCAR
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Button
              onClick={handleEliminar}
              sx={{
                backgroundColor: "#DA251C",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#030e4f",
                },

              }}
            >
              ELIMINAR ORDENES SELECCIONADAS DE MANIFIESTO
            </Button>
          </Grid>
        </Grid>
      </Paper>
      </Container>
      <Paper style={{ padding: "10px", marginBottom: "10px" }}>
        <Grid container spacing={1} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TablaGestorManifiesto ordenesDataTabla={ordenesDataTabla} setSelectedItems={setSelectedItems} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
export default GestorManifiestoSalida;
